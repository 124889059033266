import React from 'react';
import Store from '../components/Store';
import { useStoreListingStore, useUserStore } from '../state/store';
import { useQuery } from 'react-query';
import { useRepository } from '../lib/hooks';
import { TeamMarket } from '../lib/types';

const StoreContainer = () => {
  const state = useStoreListingStore();
  const userStore = useUserStore();

  const repo = useRepository();
  const market = useQuery(['team-market'], () => repo.getTeamMarket());

  if (market.isLoading) {
    return null;
  }

  // Ignore errors.
  const data: TeamMarket = market.data || { categories: [] };
  return (
    <Store
      products={state.products}
      market={data}
      availableProductTypes={state.product_types}
      order={state.order}
      filter={state.filter}
      onFilterChange={state.setFilter}
      onOrderChange={state.setOrder}
      currentUserId={userStore.id}
    />
  );
};

export default StoreContainer;
