import { useFormik } from 'formik';
import React from 'react';
import { _ } from '../../lib/l18n';
import { Redemption } from '../../lib/types';
import Button from '../Button';

const RequestRedeemForm: React.FC<{ redemptions: Redemption[]; onSubmit: (data: any) => void; isLoading?: boolean }> = ({
  redemptions,
  ...props
}) => {
  return <RequestRedeemFormInternal {...props} />;
};

const RequestRedeemFormInternal: React.FC<{ onSubmit: (data: { message?: string }) => void; isLoading?: boolean }> = ({
  onSubmit,
  isLoading,
}) => {
  const formik = useFormik({
    initialValues: { message: '' },
    onSubmit: onSubmit,
  });

  return (
    <div className="max-w-md">
      <p className="mb-8">{_('redemption:request.pleaseSubmitRequestIntro')}</p>
      <div className="max-w-sm mx-auto">
        <form onSubmit={formik.handleSubmit}>
          <div className="max-w-sm">
            <div className="font-medium mb-4">
              <label htmlFor="redemption-request-comment">{_('redemption:request.message')}</label>
            </div>
            <div className="w-full">
              <textarea
                name="message"
                placeholder={_('redemption:request.optionalComment')}
                id="redemption-request-comment"
                maxLength={1000}
                className="w-full rounded border-grey-10 border px-1 py-1"
                onChange={formik.handleChange}
                value={formik.values.message}
              />
            </div>
          </div>
          <div className="mt-4">
            <div>
              <Button primary isSubmit disabled={isLoading} spin={isLoading}>
                {_('redemption:sendRequest')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RequestRedeemForm;
