import React from 'react';
import BlankPage from './layouts/BlankPage';
import { _ } from '../lib/l18n';
import Button from './Button';

const LoggedOut: React.FC<{ goToLogin: () => void }> = ({ goToLogin }) => {
  return (
    <BlankPage>
      <div className="flex flex-1 justify-center items-center flex-col">
        <div className="max-w-sm">
          <p className="text-center mb-8 text-grey-slate">{_('logoutSuccessful')}</p>
          <p>
            <Button onClick={goToLogin} primary>
              {_('signIn')}
            </Button>
          </p>
        </div>
      </div>
    </BlankPage>
  );
};

export default LoggedOut;
