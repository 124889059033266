import { useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { _ } from '../../lib/l18n';
import { Redemption } from '../../lib/types';
import Button from '../Button';

const SelfRedeemForm: React.FC<{
  redemptions: Redemption[];
  isLoading?: boolean;
  isSuccess?: boolean;
  onContinue?: () => void;
  onSubmit: () => void;
  submitResponse?: { message: string };
}> = ({ redemptions, onSubmit, isSuccess, isLoading, submitResponse, onContinue }) => {
  const history = useHistory();
  const form = useFormik({
    initialValues: {},
    onSubmit: () => {
      onSubmit();
    },
  });
  let message = _('confirm.redeemitems', { number: redemptions.length || '?' });
  if (redemptions.length === 1) {
    message = _('confirm.redeemitem', { item: redemptions[0].item.name || '?' });
  }

  return (
    <div className="max-w-md">
      {!isSuccess ? (
        <>
          <p className="mb-8">{message}</p>
          <div className="max-w-sm mx-auto">
            <form onSubmit={form.handleSubmit}>
              <div className="max-w-sm">
                <div className="mt-4">
                  <div>
                    <Button primary isSubmit disabled={isLoading} spin={isLoading}>
                      {_('common:confirm')}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          <h1 className="text-xl mb-8 text-center">{_('congratulations')}</h1>
          <div className="my-6">
            <dl>
              <dt className="font-bold">{_('message')}</dt>
              <dd>{submitResponse?.message || ''}</dd>
            </dl>
          </div>
          <p className="my-6 text-grey-slate">{_('redemption:messageAlsoEmailed')}</p>
          <div>
            <Button primary onClick={onContinue || (() => history.push('/'))}>
              {_('continue')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default SelfRedeemForm;
