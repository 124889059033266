import React from 'react';
import nl2br from 'react-nl2br';
import Coins from './Coins';
import ContentContainer from './layouts/ContentContainer';
import Spinner from './Spinner';

type Rule = { coins: number; label: string };

const TextPlainContent: React.FC = ({ children }) => <p>{nl2br(children)}</p>;
const HtmlContent = ({ children }: { children: string }) => (
  <div className="prose" dangerouslySetInnerHTML={{ __html: children }} />
);

const InfoContent = ({ type, content }: { type: string; content: string }) => {
  if (type === 'text/plain') {
    return <TextPlainContent>{content}</TextPlainContent>;
  } else if (type === 'text/html') {
    return <HtmlContent>{content}</HtmlContent>;
  }
  return null;
};

const RulesContent = ({ rules }: { rules?: Rule[] }) => {
  if (!rules || !rules.length) {
    return null;
  }

  return (
    <dl className="flex flex-wrap md:grid md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-6 mt-6">
      {rules.map((rule, i) => {
        return (
          <div
            key={i}
            className="bg-grey-5 flex flex-row-reverse h-auto w-full md:w-auto md:flex-col justify-end md:items-center md:justify-center p-4 md:p-6 md:h-48 gap-6 md:gap-1 md:text-center"
          >
            <dt className="font-semibold">{rule.label}</dt>
            <dd className="min-w-16 md:min-w-auto">
              <Coins amount={rule.coins} />
            </dd>
          </div>
        );
      })}
    </dl>
  );
};

const Information: React.FC<{
  loading: boolean;
  type: string;
  content: string;
  rules?: Rule[];
}> = ({ loading, type, content, rules = [] }) => {
  if (loading) {
    return (
      <ContentContainer className="">
        <Spinner />
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <InfoContent type={type} content={content} />
      <RulesContent rules={rules} />
    </ContentContainer>
  );
};

export default Information;
