import React, { useContext } from 'react';
import LoggedOut from '../components/LoggedOut';
import { RootActionsContext } from '../lib/contexts';

const LoggedOutContainer: React.FC = () => {
  const { performLogin } = useContext(RootActionsContext);
  return <LoggedOut goToLogin={performLogin} />;
};

export default LoggedOutContainer;
