import React from 'react';
import LoadingScreen from '../components/LoadingScreen';
import { useGenericLoadingMessage } from '../lib/hooks';
import { _ } from '../lib/l18n';

const LoadingScreenContainer: React.FC = () => {
  const message = useGenericLoadingMessage();
  return message ? <LoadingScreen title={_(message[0])} message={_(message[1])} /> : null;
};

export default LoadingScreenContainer;
