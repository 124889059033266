import React from 'react';
import Modal from './Modal';
import Spinner from './Spinner';

const LoadingOverlay: React.FC<{
  opened: boolean;
  title: string;
  message?: string;
}> = ({ opened, title, message }) => {
  return (
    <Modal isOpen={opened}>
      <div className="flex flex-col items-center">
        <div className=" mb-2">
          <h1 className="text-xl font-medium">{title}</h1>
        </div>
        {message ? <p className="mb-2">{message}</p> : null}
        <div className="my-4">
          <Spinner />
        </div>
      </div>
    </Modal>
  );
};

export default LoadingOverlay;
