import React, { useRef } from 'react';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';
import CarretDownHoverImg from '../assets/icons/carret-down-hover.svg';
import CarretDownImg from '../assets/icons/carret-down.svg';
import CarretImg from '../assets/icons/carret.svg';
import InfoHoverIcon from '../assets/icons/info-hover.svg';
import InfoIcon from '../assets/icons/info.svg';
import MenuIcon from '../assets/icons/menu.svg';
import RewardHoverIcon from '../assets/icons/reward-hover.svg';
import RewardIcon from '../assets/icons/reward.svg';
import ShoppingCartHoverIcon from '../assets/icons/shopping-cart-hover.svg';
import ShoppingCartIcon from '../assets/icons/shopping-cart.svg';
import UserHoverIcon from '../assets/icons/user-hover.svg';
import UserIcon from '../assets/icons/user.svg';
import CloseIcon from '../assets/icons/x-close.svg';
import { _ } from '../lib/l18n';
import {
  cartUrl,
  getProductUrl,
  getRedemptionsFilterUrl,
  infoUrl,
  leaderboardUrl,
  logoutUrl,
  profileUrl,
  redemptionsUrlPaths,
  storeUrl,
} from '../lib/urls';
import { RedemptionFilter } from '../lib/types.local';
import CoinsIcon from './CoinsIcon';
import { useLeaderboardStore } from '../state/store';
import { useIsEmbedded } from '../lib/hooks';
import ContentContainer from './layouts/ContentContainer';
import { TicketsIcon } from './TicketsIcon';
import { HasTicketsEnabled } from './utils/HasTicketsEnabled';
import { User } from '../lib/types';

const Badge: React.FC<{ count: number }> = ({ count }) => {
  return (
    <div
      className="rounded-full bg-grey text-white flex items-center justify-center"
      style={{ width: '15px', height: '15px', fontSize: count > 9 ? '9px' : '10px' }}
    >
      {count > 9 ? '9+' : count}
    </div>
  );
};

export const MenuButton: React.FC<{ opened: boolean; onClick: () => void }> = ({ opened, onClick }) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClick();
  };
  return (
    <div className="flex justify-end items-center select-none" style={{ width: '30px' }}>
      <a href="#menu" onClick={handleClick} className="outline-none">
        <img src={opened ? CloseIcon : MenuIcon} alt={opened ? _('closeMenu') : _('openMenu')} />
      </a>
    </div>
  );
};

const StaticMenuItemDesktop: React.FC<{ icon: React.ReactNode; label: string }> = ({ icon, label, children }) => {
  return (
    <div className="h-full flex items-center">
      <div className="h-full flex items-center mx-3 text-grey">
        <div className="flex items-center gap-2">
          <div className="flex">{icon}</div>
          <span className="sr-only">{label}</span>
          {children}
        </div>
      </div>
    </div>
  );
};

const MenuDropdownDesktop: React.FC<{
  icon: string;
  iconHover: string;
  opened?: boolean;
  label: string;
  count?: number;
  activePath: string | string[];
  onToggle: (ref: React.RefObject<HTMLInputElement>) => void;
  snapRight?: boolean;
}> = ({ children, icon, iconHover, opened, label, count, onToggle, activePath, snapRight = false }) => {
  const match = useRouteMatch(activePath);
  const ref = useRef<HTMLInputElement>(null);

  const isActive = Boolean(match) || opened;
  let classes = isActive ? 'text-grey-dark' : '';

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onToggle(ref);
  };

  return (
    <div className="header-menu-item h-full flex items-center relative" ref={ref}>
      <a
        onClick={handleClick}
        href={`#dropdown`}
        className={`h-full flex items-center mx-1 text-grey hover:no-underline outline-none group hover:text-grey-dark ${classes}`}
      >
        <div className="flex items-center">
          <div className={`relative py-2 px-2 flex`}>
            <img src={icon} alt="" className={`${isActive ? 'hidden' : ''} group-hover:hidden`} />
            <img src={iconHover} alt="" className={`${isActive ? 'inline' : 'hidden'} group-hover:inline`} />
            {count ? (
              <div className="absolute top-0 right-0">
                <Badge count={count} />
              </div>
            ) : null}
          </div>
          <div className="">
            <img src={CarretImg} alt="" />
          </div>
          <span className="sr-only">{label}</span>
        </div>
      </a>
      {opened ? (
        <div
          className={`${
            snapRight ? 'right-0' : 'left-0'
          } w-44 absolute top-full bg-white border shadow-dropdown border-grey-30 normal-case pt-2 px-3 pb-2 leading-normal z-10`}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};

const MenuDropdownItemDesktop: React.FC<{
  label: string;
  count?: number;
  path?: string;
}> = ({ children, label, count, path }) => {
  return (
    <div className="h-full flex items-center mb-2">
      {path ? (
        <Link to={path} className="text-grey hover:underline">
          <div className="flex items-center gap-1">
            {label}
            {count ? (
              <div>
                <Badge count={count} />
              </div>
            ) : null}
          </div>
        </Link>
      ) : (
        <div className="font-medium">{label}</div>
      )}
    </div>
  );
};

const MenuItemDesktop: React.FC<{
  icon?: string;
  iconHover?: string;
  label?: string;
  count?: number;
  showLabel?: boolean;
  path: string;
  exact?: boolean;
  isActive?: (...args: any) => boolean;
}> = ({ children, icon, iconHover, label, showLabel, path, exact, count, isActive }) => {
  let classes = '';
  if (!icon) {
    classes += ' hover:border-b-4 border-turquoise hover:pt-4px';
  }
  return (
    <div className="header-menu-item h-full flex items-center px-3 first:ps-0">
      <NavLink
        to={path}
        exact={exact}
        className={`h-full flex items-center text-grey hover:no-underline outline-none group hover:text-grey-dark ${classes}`}
        activeClassName={`active ${!icon ? 'border-b-4 pt-4px' : ''}`}
        isActive={isActive}
      >
        <div className="flex items-center relative px-2 py-1 gap-2">
          {icon ? (
            <div className={`flex ${children ? '' : ''}`}>
              <img src={icon} alt="" className="group-hover:hidden" />
              <img src={iconHover} alt="" className="hidden group-hover:inline" />
            </div>
          ) : null}
          {count ? (
            <div className="absolute top-0 right-0">
              <Badge count={count} />
            </div>
          ) : null}
          <span className={`${showLabel ? '' : 'sr-only'}`}>{label}</span>
          {children}
        </div>
      </NavLink>
    </div>
  );
};

const StaticMenuItemResponsive: React.FC<{ icon: React.ReactNode; label: string }> = ({ icon, label, children }) => {
  return (
    <div className="py-2">
      <div className="text-grey block outline-none leading-tight">
        <ContentContainer className="flex">
          <div className="flex" style={{ width: '44px' }}>
            {icon}
          </div>
          <span className="sr-only">{label}</span>
          {children}
        </ContentContainer>
      </div>
    </div>
  );
};

const MenuItemResponsive: React.FC<{
  isActive?: (...args: any) => boolean;
  count?: number;
  icon?: string;
  iconHover?: string;
  path: string;
  exact?: boolean;
}> = ({ children, icon, iconHover, path, exact, isActive, count }) => {
  return (
    <div className="py-2 header-menu-item">
      <NavLink
        to={path}
        exact={exact}
        className="text-grey hover:no-underline block outline-none group hover:text-grey-dark leading-tight"
        activeClassName="active text-grey-dark"
        isActive={isActive}
      >
        <ContentContainer className="flex items-center">
          {icon ? (
            <div className="flex" style={{ width: '44px' }}>
              <img src={icon} alt="" className="group-hover:hidden" />
              <img src={iconHover} alt="" className="hidden group-hover:inline" />
            </div>
          ) : null}
          {children}
          {count ? (
            <div className="ms-2">
              <Badge count={count} />
            </div>
          ) : null}
        </ContentContainer>
      </NavLink>
    </div>
  );
};

const MenuDropdownResponsive: React.FC<{
  icon: string;
  iconHover: string;
  opened?: boolean;
  label: string;
  count?: number;
  activePath: string | string[];
  onToggle: (ref: React.RefObject<HTMLInputElement>) => void;
}> = ({ children, icon, iconHover, opened, label, count, onToggle, activePath }) => {
  const match = useRouteMatch(activePath);
  const ref = useRef<HTMLInputElement>(null); // We do not use it in responsive mode.

  const isActive = Boolean(match) || opened;
  let classes = isActive ? 'active  text-grey-dark' : '';

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onToggle(ref);
  };

  return (
    <div className="header-menu-item py-2">
      <a
        onClick={handleClick}
        href={`#dropdown`}
        className={`text-grey hover:no-underline block outline-none group hover:text-grey-dark leading-tight ${classes}`}
      >
        <ContentContainer className="flex items-center">
          <div className="flex flex-1 items-center">
            {icon ? (
              <div className="flex" style={{ width: '44px' }}>
                <img src={icon} alt="" className={`${isActive ? 'hidden' : ''} group-hover:hidden`} />
                <img src={iconHover} alt="" className={`${isActive ? 'inline' : 'hidden'} group-hover:inline`} />
              </div>
            ) : null}
            {label}
            {count ? (
              <div className="ms-2">
                <Badge count={count} />
              </div>
            ) : null}
          </div>
          <div className="me-4">
            <img src={CarretDownImg} alt="" className={`${isActive ? 'hidden' : ''} group-hover:hidden`} />
            <img src={CarretDownHoverImg} alt="" className={`${isActive ? 'inline' : 'hidden'} group-hover:inline`} />
          </div>
        </ContentContainer>
      </a>
      {opened ? (
        <ContentContainer className="normal-case flex leading-loose">
          <div style={{ width: '44px' }}></div>
          <div className="mt-1">{children}</div>
        </ContentContainer>
      ) : null}
    </div>
  );
};

const MenuDropdownItemResponsive: React.FC<{
  label: string;
  count?: number;
  path: string;
}> = ({ children, label, count, path }) => {
  return (
    <div className="flex items-center">
      <Link to={path} className="text-grey hover:text-grey-dark hover:no-underline">
        <div className="flex items-center">
          {label}
          {count ? (
            <div className="ms-2">
              <Badge count={count} />
            </div>
          ) : null}
        </div>
      </Link>
    </div>
  );
};

type MenuItems = React.FC<{
  coins: number;
  tickets: number;
  level: User['level'];
  rewards?: number;
  cart?: number;
  openedDropdown?: string;
  onDropdownToggle?: (id: string, ref: React.RefObject<HTMLInputElement>) => void;
}>;

export const MenuItemsDesktop: MenuItems = ({ coins, tickets, level, rewards, cart, openedDropdown, onDropdownToggle }) => {
  const { anyEnabled: showLeaderboard } = useLeaderboardStore();
  const isEmbedded = useIsEmbedded();
  return (
    <div className="hidden uppercase md:flex md:h-full items-center justify-between flex-2 leading-none">
      <div className="flex h-full items-center">
        <MenuItemDesktop showLabel label={_('shop')} path={storeUrl} exact isActive={isStoreLinkActive}></MenuItemDesktop>
        {showLeaderboard ? <MenuItemDesktop showLabel label={_('leaderboard')} path={leaderboardUrl}></MenuItemDesktop> : null}
      </div>
      <div className="flex h-full items-center">
        <StaticMenuItemDesktop icon={<CoinsIcon plain />} label={_('coins')}>
          <span className="text-gunmetal-2 group-hover:text-grey-dark">{coins}</span>
        </StaticMenuItemDesktop>
        <HasTicketsEnabled>
          <StaticMenuItemDesktop icon={<TicketsIcon />} label={_('tickets')}>
            <span className="text-gunmetal-2 group-hover:text-grey-dark">{tickets}</span>
          </StaticMenuItemDesktop>
        </HasTicketsEnabled>
        <div className="h-full flex items-center">
          <div className="h-full flex items-center mx-3 py-2">
            {level.badge_url ? (
              <img src={level.badge_url} aria-hidden="true" className="h-full min-w-8" alt="" />
            ) : (
              <div className="w-8" />
            )}
            <span className="sr-only">{_('levelx', { n: level.number })} </span>
          </div>
        </div>
        <MenuDropdownDesktop
          icon={UserIcon}
          iconHover={UserHoverIcon}
          label={_('profile')}
          count={rewards}
          opened={openedDropdown === 'profile'}
          onToggle={(ref) => onDropdownToggle && onDropdownToggle('profile', ref)}
          activePath={profileUrl}
          snapRight
        >
          <MenuDropdownItemDesktop path={infoUrl} label={_('info')} />
          <MenuDropdownItemDesktop label={_('redemptions.title')} />
          <MenuDropdownItemDesktop
            label={_('redemptionsPending')}
            count={rewards}
            path={getRedemptionsFilterUrl(RedemptionFilter.Pending)}
          />
          <MenuDropdownItemDesktop label={_('redemptionsCompleted')} path={getRedemptionsFilterUrl(RedemptionFilter.Completed)} />
          <MenuDropdownItemDesktop label={_('profile')} />
          <MenuDropdownItemDesktop label={_('recentActivity')} path={profileUrl} />
          {!isEmbedded ? <MenuDropdownItemDesktop label={_('logout')} path={logoutUrl} /> : null}
        </MenuDropdownDesktop>

        <MenuItemDesktop
          path={cartUrl}
          icon={ShoppingCartIcon}
          iconHover={ShoppingCartHoverIcon}
          label={_('cart')}
          count={cart}
        ></MenuItemDesktop>
      </div>
    </div>
  );
};

export const MenuItemsResponsive: MenuItems = ({ coins, tickets, rewards, cart, onDropdownToggle, openedDropdown }) => {
  const { anyEnabled: showLeaderboard } = useLeaderboardStore();
  const isEmbedded = useIsEmbedded();
  return (
    <div className="md:hidden  uppercase pb-2 shadow-menu absolute w-full bg-grey-light">
      <MenuItemResponsive path={storeUrl} exact isActive={isStoreLinkActive}>
        {_('shop')}
      </MenuItemResponsive>
      {showLeaderboard ? <MenuItemResponsive path={leaderboardUrl}>{_('leaderboard')}</MenuItemResponsive> : null}
      <StaticMenuItemResponsive icon={<CoinsIcon plain />} label={_('coins')}>
        {coins}
      </StaticMenuItemResponsive>
      <HasTicketsEnabled>
        <StaticMenuItemResponsive icon={<TicketsIcon />} label={_('tickets')}>
          {tickets}
        </StaticMenuItemResponsive>
      </HasTicketsEnabled>
      <MenuDropdownResponsive
        icon={RewardIcon}
        iconHover={RewardHoverIcon}
        label={_('redemptions.title')}
        count={rewards}
        opened={openedDropdown === 'rewards'}
        onToggle={(ref) => onDropdownToggle && onDropdownToggle('rewards', ref)}
        activePath={redemptionsUrlPaths}
      >
        <MenuDropdownItemResponsive
          label={_('redemptionsPending')}
          count={rewards}
          path={getRedemptionsFilterUrl(RedemptionFilter.Pending)}
        />
        <MenuDropdownItemResponsive label={_('redemptionsCompleted')} path={getRedemptionsFilterUrl(RedemptionFilter.Completed)} />
      </MenuDropdownResponsive>
      {!isEmbedded ? (
        <MenuDropdownResponsive
          icon={UserIcon}
          iconHover={UserHoverIcon}
          label={_('profile')}
          opened={openedDropdown === 'profile'}
          onToggle={(ref) => onDropdownToggle && onDropdownToggle('profile', ref)}
          activePath={profileUrl}
        >
          <MenuDropdownItemResponsive label={_('recentActivity')} path={profileUrl} />
          <MenuDropdownItemResponsive label={_('logout')} path={logoutUrl} />
        </MenuDropdownResponsive>
      ) : (
        <MenuItemResponsive path={profileUrl} icon={UserIcon} iconHover={UserHoverIcon}>
          {_('profile')}
        </MenuItemResponsive>
      )}
      <MenuItemResponsive path={cartUrl} icon={ShoppingCartIcon} iconHover={ShoppingCartHoverIcon} count={cart}>
        {_('cart')}
      </MenuItemResponsive>
      <MenuItemResponsive path={infoUrl} icon={InfoIcon} iconHover={InfoHoverIcon}>
        {_('info')}
      </MenuItemResponsive>
    </div>
  );
};

function isStoreLinkActive(match: any, location: any) {
  return match || location.pathname.indexOf(getProductUrl('')) === 0;
}
