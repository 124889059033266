import { createContext } from 'react';
import { DummyRepository, Repository } from './repository';

export const AppContext = createContext({
  embedded: false,
});

export const RepositoryContext = createContext<Repository>(new DummyRepository());

export const RootActionsContext = createContext<{
  performLogin: () => void;
  performLogout: () => void;
  sendAuthenticationEmail: (email: string) => Promise<void>;
}>({
  performLogin: () => {},
  performLogout: () => {},
  sendAuthenticationEmail: async (email: string) => {},
});
