import addMinutes from 'date-fns/addMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import isFuture from 'date-fns/isFuture';
import isPast from 'date-fns/isPast';

export const diff = (laterDate: Date, earlierDate: Date) => {
  return differenceInSeconds(laterDate, earlierDate);
};
export const now = () => Math.floor(Date.now() / 1000);

export { addMinutes, isFuture, isPast };
