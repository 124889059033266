import React from 'react';
import { _ } from '../lib/l18n';
import Button from './Button';
import PoweredBy from './PoweredBy';
import BlankPage from './layouts/BlankPage';
import { StaticToast } from './Toast';

export const LoginWhileEmbedded = () => {
  return (
    <BlankPage>
      <div className="flex flex-1 justify-center items-center flex-col">
        <div className="max-w-sm">
          <p className="text-center mb-8 text-grey-slate">{_('youAreNotLoggedIn')}</p>
          <p className="text-center mb-8">{_('refreshThePageToLogin')}</p>
          <PoweredBy className="mt-24" />
        </div>
      </div>
    </BlankPage>
  );
};

const Login: React.FC<{
  loading?: boolean;
  canEdit?: boolean;
  canSubmit?: boolean;
  email: string;
  errorMessage?: string;
  successMessage?: string;
  onEmailChange: (email: string) => void;
  onSubmit: () => void;
}> = ({ loading, canSubmit, canEdit, email, successMessage, errorMessage, onEmailChange, onSubmit }) => {
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onEmailChange(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!canSubmit) return;
    onSubmit();
  };

  return (
    <BlankPage>
      <div className="flex flex-1 justify-center items-center flex-col">
        <div className="max-w-sm">
          <p className="text-center mb-8 text-grey-slate">{_('provideEmailForAuthLink')}</p>
          <form onSubmit={handleSubmit} action="">
            <div className="flex border-grey-30 shadow-item border rounded" style={{ padding: '2px' }}>
              <div className="flex-1">
                <input
                  type="email"
                  className="h-full px-4 disabled:bg-white disabled:text-grey-disabled outline-none w-full"
                  onChange={handleEmailChange}
                  value={email}
                  disabled={!canEdit}
                />
              </div>
              <div style={{ minWidth: '5rem' }}>
                <Button primary disabled={!canSubmit} spin={loading} isSubmit>
                  {_('send')}
                </Button>
              </div>
            </div>
          </form>
          {errorMessage ? <div className="text-red mt-4 text-center">{errorMessage}</div> : null}
          {successMessage ? (
            <div className="mt-8">
              <StaticToast message={successMessage} className="toast-appears w-full" />
            </div>
          ) : null}
          <PoweredBy className="mt-12" />
        </div>
      </div>
    </BlankPage>
  );
};

export default Login;
