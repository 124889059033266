import React from 'react';
import ReactSelect, { components as SelectComponents, IndicatorProps, Props } from 'react-select';
import selectCarretOpen from '../assets/icons/select-carret-open.svg';
import selectCarretClose from '../assets/icons/select-carret-close.svg';
import { _ } from '../lib/l18n';

const DropdownIndicator = (props: IndicatorProps<any, any>) => {
  return (
    <SelectComponents.DropdownIndicator {...props}>
      <img src={props.selectProps.menuIsOpen ? selectCarretClose : selectCarretOpen} alt="" />
    </SelectComponents.DropdownIndicator>
  );
};

const isDisabled = (o: { value: string; label: string; disabled?: boolean }) => {
  return Boolean(o.disabled);
};

const Select = (props: Props) => {
  return (
    <ReactSelect
      className="select"
      classNamePrefix="select"
      placeholder={_('chooseDots')}
      components={{ DropdownIndicator: DropdownIndicator }}
      isSearchable={false}
      isOptionDisabled={isDisabled}
      {...props}
    />
  );
};

export default Select;
