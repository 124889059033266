import React from 'react';
import Modal from './Modal';
import Button from './Button';
import { _ } from '../lib/l18n';

const ConfirmModal: React.FC<{
  opened: boolean;
  title: string;
  cancelLabel?: string;
  confirmLabel: string;
  onCancel: () => void;
  onConfirm: () => void;
}> = ({ opened, title, children, cancelLabel = _('cancel'), onCancel, confirmLabel, onConfirm }) => {
  const handleConfirm = () => onConfirm();
  const handleCancel = () => onCancel();

  return (
    <Modal isOpen={opened} onRequestClose={handleCancel}>
      <h1 className="text-xl mb-6">{title}</h1>
      {children}
      <div className="mt-8 flex flex-wrap-reverse -mx-2 -mb-2 md:justify-end md:-mr-8">
        <div className="flex-grow mx-2 mb-2 md:flex-grow-0 md:min-w-24">
          <Button onClick={handleCancel}>{cancelLabel}</Button>
        </div>
        <div className="flex-grow mx-2 mb-2 md:flex-grow-0 md:min-w-36">
          <Button onClick={handleConfirm} primary>
            {confirmLabel}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
