import React from 'react';
import { useBrandingStore, useLeaderboardStore, useStoreListingStore } from '../state/store';
import LoadingScreenContainer from './LoadingScreen';

const AppLoader: React.FC = ({ children }) => {
  const { loaded } = useStoreListingStore();
  const { loaded: leaderboardLoaded, anyEnabled: anyLeaderboardEnabled } = useLeaderboardStore();
  const { loaded: brandingLoaded } = useBrandingStore();

  const hasCheckedLeaderboard = leaderboardLoaded || anyLeaderboardEnabled;
  const isLoaded = loaded && brandingLoaded && hasCheckedLeaderboard;

  if (!isLoaded) return <LoadingScreenContainer />;

  return <>{children}</>;
};

export default AppLoader;
