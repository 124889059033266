import React, { useMemo } from 'react';
import EmptyStateImg from '../assets/empty-state.svg';
import FrownImg from '../assets/frown.svg';
import { now } from '../lib/date';
import { useRefresher } from '../lib/hooks';
import { getChanceOfWinning, getCurrencyComponent, hasUnlimitedStock, isAvailable } from '../lib/item';
import {
  _,
  getChanceOfWinningSentence,
  getDefaultTeamMarketCategoryName,
  getTypeName,
  getTypeNameFromFilter,
  timeLeft,
} from '../lib/l18n';
import { ItemType, TeamMarket } from '../lib/types';
import { Product, StoreFilter, StoreOrder } from '../lib/types.local';
import { getProductUrl } from '../lib/urls';
import ItemTile from './ItemTile';
import ItemTileList from './ItemTileList';
import PillMenu, { PillMenuItem } from './PillMenu';
import ContentContainer from './layouts/ContentContainer';

const isEndingSoon = (item: Product) => {
  return item.available_until <= now() + 5 * 86400;
};

const isStockLow = (item: Product) => {
  return item.type === ItemType.Purchase && (item.remaining_stock <= 5 || item.remaining_stock < item.quantity * 0.1);
};

const isSoldout = (item: Product) => {
  return item.type === ItemType.Purchase && !hasUnlimitedStock(item) && item.remaining_stock <= 0;
};

const Store: React.FC<{
  currentUserId: string;
  market: TeamMarket;
  products: Product[];
  availableProductTypes: ItemType[];
  filter?: StoreFilter;
  order?: StoreOrder;
  onFilterChange: (filter: StoreFilter) => void;
  onOrderChange: (order: StoreOrder) => void;
}> = ({ currentUserId, products, market, filter = StoreFilter.All, order = StoreOrder.EndingSoon, onOrderChange }) => {
  let displayProducts = useMemo(() => {
    let p =
      filter === StoreFilter.All
        ? products
        : products.filter((i) => {
            if (filter === StoreFilter.BuyNow) return i.type === ItemType.Purchase;
            if (filter === StoreFilter.Raffle) return i.type === ItemType.Raffle;
            if (filter === StoreFilter.Auction) return i.type === ItemType.Auction;
            if (filter === StoreFilter.Contribution) return i.type === ItemType.Contribution;
            if (filter === StoreFilter.Sweepstakes) return i.type === ItemType.Sweepstakes;
            return false;
          });
    return p
      .sort((i1, i2) => {
        if (!i1.remaining_stock || !i2.remaining_stock) return -(i1.remaining_stock - i2.remaining_stock); // Sold out always last.
        if (order === StoreOrder.EndingSoon) return i1.available_until - i2.available_until;
        if (order === StoreOrder.Availability) return i1.remaining_stock - i2.remaining_stock;
        return 0;
      })
      .filter(isAvailable);
  }, [products, filter, order]);

  return (
    <ContentContainer className="flex flex-col flex-grow">
      <StoreContent
        currentUserId={currentUserId}
        market={market}
        products={displayProducts}
        filter={filter}
        order={order}
        onOrderChange={onOrderChange}
      />
    </ContentContainer>
  );
};

const StoreContentFilters = ({
  onChange,
  filter,
  productTypes,
}: {
  onChange: (filter: StoreFilter) => void;
  filter: StoreFilter;
  productTypes: ItemType[];
}) => {
  if (!productTypes.length) return null;
  return (
    <PillMenu>
      <PillMenuItem onClick={() => onChange(StoreFilter.All)} active={filter === StoreFilter.All}>
        {_('allItems')}
      </PillMenuItem>
      {productTypes.includes(ItemType.Purchase) ? (
        <PillMenuItem onClick={() => onChange(StoreFilter.BuyNow)} active={filter === StoreFilter.BuyNow}>
          {_('typeBuyNow')}
        </PillMenuItem>
      ) : null}
      {productTypes.includes(ItemType.Auction) ? (
        <PillMenuItem onClick={() => onChange(StoreFilter.Auction)} active={filter === StoreFilter.Auction}>
          {_('typeAuction')}
        </PillMenuItem>
      ) : null}
      {productTypes.includes(ItemType.Raffle) ? (
        <PillMenuItem onClick={() => onChange(StoreFilter.Raffle)} active={filter === StoreFilter.Raffle}>
          {_('typeRaffle')}
        </PillMenuItem>
      ) : null}
      {productTypes.includes(ItemType.Contribution) ? (
        <PillMenuItem onClick={() => onChange(StoreFilter.Contribution)} active={filter === StoreFilter.Contribution}>
          {_('item.type.contribution')}
        </PillMenuItem>
      ) : null}
      {productTypes.includes(ItemType.Sweepstakes) ? (
        <PillMenuItem onClick={() => onChange(StoreFilter.Sweepstakes)} active={filter === StoreFilter.Sweepstakes}>
          {_('item.type.sweepstakes')}
        </PillMenuItem>
      ) : null}
    </PillMenu>
  );
};

const StoreContentOrder = ({ onChange, order }: { onChange: (order: StoreOrder) => void; order: StoreOrder }) => {
  return (
    <div className="mb-4 mt-6">
      {_('sortByColon')}{' '}
      <a
        href="#endingsoon"
        onClick={() => onChange(StoreOrder.EndingSoon)}
        className={`${order === StoreOrder.EndingSoon ? 'underline' : ''}`}
      >
        {_('endingSoon')}
      </a>{' '}
      |{' '}
      <a
        href="#availability"
        onClick={() => onChange(StoreOrder.Availability)}
        className={`${order === StoreOrder.Availability ? 'underline' : ''}`}
      >
        {_('availability')}
      </a>
    </div>
  );
};

const StoreContent: React.FC<{
  currentUserId: string;
  products: Product[];
  market: TeamMarket;
  order: StoreOrder;
  filter: StoreFilter;
  onOrderChange: (order: StoreOrder) => void;
}> = ({ currentUserId: userId, products, filter, market }) => {
  const categories = useMemo(() => {
    if (!products.length) return [];
    let usedIds = market.categories.reduce<string[]>((carry, cat) => [...carry, ...(cat.type !== 'overflow' ? cat.items : [])], []);
    return market.categories
      .map((cat) => {
        let catProducts: Product[] = [];
        if (cat.type === 'items') {
          catProducts = products
            .filter((p) => cat.items.includes(p.id))
            .sort((p1, p2) => cat.items.indexOf(p1.id) - cat.items.indexOf(p2.id));
        } else if (cat.type === 'overflow') {
          catProducts = products.filter((p) => !usedIds.includes(p.id));
        }
        return {
          id: cat.id,
          name: cat.name,
          products: catProducts,
          defaultName: getDefaultTeamMarketCategoryName(cat),
        };
      })
      .filter((cat) => cat.products.length > 0) as { id: string; name: string; products: Product[]; defaultName: string }[];
  }, [products, market]);

  if (!products.length || !categories.length) {
    return <StoreContentEmpty filter={filter} />;
  }

  return (
    <>
      <div className="space-y-2">
        {categories.map((cat) => (
          <StoreCategory
            key={cat.id}
            name={cat.name || cat.defaultName}
            showName={categories.length > 1}
            products={cat.products}
            currentUserId={userId}
          />
        ))}
      </div>
    </>
  );
};

const StoreCategory = ({
  products,
  showName,
  name,
  currentUserId,
}: {
  products: Product[];
  name: string;
  showName?: boolean;
  currentUserId: string;
}) => {
  return (
    <div>
      {showName ? <h2 className="text-lg font-semibold mb-4">{name}</h2> : null}
      <ItemTileList>
        {products.map((i) => (
          <ItemTileFromProduct key={i.id} product={i} userId={currentUserId} />
        ))}
      </ItemTileList>
    </div>
  );
};

const ItemTileFromProduct = ({ product, userId }: { product: Product; userId: string }) => {
  // Refresh every now and then to update the time left, and items no longer available.
  useRefresher();

  const isContribution = product.type === ItemType.Contribution;
  let goal;
  let goalProgress;
  let cost = product.cost;
  let costSuffix;
  let tag;

  if (isSoldout(product)) {
    tag = _('soldOutExcl');
  } else if (isEndingSoon(product)) {
    tag = timeLeft(new Date(product.available_until * 1000));
  } else if (isStockLow(product)) {
    tag = _('onlyXLeft', { quantity: product.remaining_stock });
  }

  if (isContribution) {
    const contributed = product.item?.contribution?.coins || 0;
    goal = product.item?.contribution?.goal || 0;
    cost = goal;
    costSuffix = _('contribution.goal');
    goalProgress = goal ? contributed / goal : 0;
    if (goalProgress >= 1) {
      tag = _('contribution.goalMet');
    }
  }

  let info;
  let highlightInfo = product.type === ItemType.Auction;
  if (product.type === ItemType.Auction && product.item?.auction?.bidder === userId) {
    info = _('youHaveWinningBid');
  } else if (product.type === ItemType.Raffle && product.item) {
    const chanceOfWinning = getChanceOfWinning(product.item, userId);
    if (chanceOfWinning > 0) {
      info = getChanceOfWinningSentence(chanceOfWinning);
    }
  }

  return (
    <ItemTile
      key={product.id}
      itemUrl={getProductUrl(product.id)}
      type={getTypeName(product.type)}
      name={product.name}
      cost={cost}
      costSuffix={costSuffix}
      goal={goal}
      goalProgress={goalProgress}
      tag={tag}
      image={product.thumbnail_url}
      info={info}
      highlightInfo={highlightInfo}
      currencyComponent={getCurrencyComponent(product)}
    />
  );
};

const StoreContentEmpty: React.FC<{ filter: StoreFilter }> = ({ filter }) => {
  const title =
    filter === StoreFilter.All ? _('noProductsYet') : _('noProductTypeAvailable', { itemtype: getTypeNameFromFilter(filter) });
  const imgSrc = filter === StoreFilter.All ? EmptyStateImg : FrownImg;
  const maxHeight = filter === StoreFilter.All ? 306 : 144;
  const message = filter === StoreFilter.All ? _('stayTunedForShop') : _('checkOtherTypesOfItems');

  return (
    <div className="flex md:flex-grow justify-center items-center py-6">
      <div className="py-8 lg:w-4/5 px-4 justify-center flex flex-col text-center bg-grey-light rounded-lg">
        <img src={imgSrc} alt="" style={{ maxHeight: `${maxHeight}px` }} />
        <h1 className="mt-8 mb-4 md:text-3xl text-2xl">{title}</h1>
        <div className="text-grey-steel text-xl">{message}</div>
      </div>
    </div>
  );
};

export default Store;
