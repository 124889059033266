import React from 'react';
import { NotificationError } from './Notification';
import nl2br from 'react-nl2br';

/**
 * Item detail.
 *
 * Pass the form as a child.
 */
const Item: React.FC<{
  type: string;
  name: string;
  image: string;
  note?: string;
  description: string;
  info?: string;
  error?: string;
  onErrorClose?: () => void;
}> = ({ type, name, image, note, description, info, error, onErrorClose, children }) => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="mb-6 w-full md:flex-1 md:mb-0 md:mr-8">
        {error ? (
          <div className="mb-4 w-full md:max-w-xl md:mx-auto">
            <NotificationError onClose={onErrorClose}>{error}</NotificationError>
          </div>
        ) : null}
        <img src={image} alt={name} className="w-full max-w-xl m-auto" />
      </div>
      <div className="md:w-80 lg:w-80 flex-width">
        <div className="mb-2">{type}</div>
        <h1 className="text-3xl leading-none mb-2">{name}</h1>
        {note ? <div className="mb-2 opacity-80 italic">{note}</div> : null}
        <div className="mb-4 leading-tight">{nl2br(description)}</div>
        {info ? (
          <div className="flex-grow flex items-center mb-4">
            <div className={`text-center rounded-lg py-1 px-4 bg-grey-5 text-grey-dark-2`}>
              <div className="opacity-80">{info}</div>
            </div>
          </div>
        ) : null}
        {children}
      </div>
    </div>
  );
};

export default Item;
