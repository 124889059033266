import { _ } from '../../lib/l18n';
import { Redemption } from '../../lib/types';

export default function OrderFootnote({ options }: { options: Redemption['options'] }) {
  let footnote = options?.support_email
    ? _('contactSupportEmailForQuestionsAboutOrders', { email: options.support_email })
    : _('contactSupportForQuestionsAboutOrders');

  return (
    <div className="w-full text-sm text-grey-slate border-t border-silver mt-12 pt-4">
      <p>{footnote}</p>
    </div>
  );
}
