import React from 'react';
import { _ } from '../lib/l18n';
import { brandName, brandUrl, logoSrc } from '../lib/flavour';

const PoweredBy: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={`flex flex-col items-center text-center text-xs text-grey-steel ${className || ''}`}>
      <div className="mb-1">{_('poweredBy')}</div>
      <a href={brandUrl} target="_blank" rel="noopener noreferrer">
        <img src={logoSrc} alt={brandName} className="w-40 max-h-8" />
      </a>
    </div>
  );
};

export default PoweredBy;
