import { CurrencyComponent } from '../lib/types.local';
import { TicketsIcon } from './TicketsIcon';

const Tickets: CurrencyComponent = ({ amount, className }) => {
  return (
    <div className={`flex items-center gap-2 ${className || ''}`}>
      <TicketsIcon className="" />
      {amount.toLocaleString()}
    </div>
  );
};

export default Tickets;
