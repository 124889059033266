import { Field, Form, Formik, useField } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { _ } from '../../lib/l18n';
import { Redemption, RedemptionShippingOptions } from '../../lib/types';
import { getShippingRedemptionSchema } from '../../lib/validation';
import { getCountryList, getCountryStates } from '../../lib/world';
import { useUserStore } from '../../state/store';
import Button from '../Button';
import OrderFootnote from './OrderFootnote';

const FieldBase: React.FC<any> = ({ label, children, ...props }) => {
  const [, meta] = useField(props);
  const hasError = meta.touched && meta.error;
  const fieldId = props.id || props.name;
  return (
    <div className="flex flex-col md:flex-row my-4 md:my-2">
      <div className="md:flex-1 md:mr-4 mb-2 md:mb-0 md:pt-2">
        <label className="font-medium" htmlFor={fieldId}>
          {label}
        </label>
      </div>
      <div className="md:flex-3">
        {children}
        {hasError ? (
          <div className="mt-2 text-red" id={`${fieldId}-error`}>
            {meta.error}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const TextField: React.FC<any> = (props) => {
  const [field, meta] = useField(props);
  const hasError = meta.touched && meta.error;
  const fieldId = props.id || props.name;
  return (
    <FieldBase {...props}>
      <Field
        as="input"
        type="text"
        id={fieldId}
        className={`w-full ${!hasError ? 'border-grey-30' : 'border-red'} shadow-item border rounded px-4 py-2`}
        aria-invalid={hasError ? 'true' : null}
        aria-describedby={hasError ? `${fieldId}-error` : null}
        {...field}
        {...props}
      />
    </FieldBase>
  );
};

const CountryField: React.FC<any & { label: string; allowedCountries?: string[] }> = ({ allowedCountries, ...props }) => {
  const [field, meta] = useField(props);
  const hasError = meta.touched && meta.error;
  const fieldId = props.id || props.name;

  const allCountries = getCountryList();
  const countryCodes = useMemo(() => {
    let countryCodes = Object.keys(allCountries);
    if (allowedCountries) {
      countryCodes = countryCodes.filter((c) => allowedCountries.includes(c));
    }
    return countryCodes;
  }, [allCountries, allowedCountries]);

  return (
    <FieldBase {...props}>
      <Field
        as="select"
        id={fieldId}
        className={`w-full bg-white ${!hasError ? 'border-grey-30' : 'border-red'} shadow-item border rounded px-4 py-2`}
        aria-invalid={hasError ? 'true' : null}
        aria-describedby={hasError ? `${fieldId}-error` : null}
        {...field}
        {...props}
      >
        {countryCodes.length > 1 ? <option value="">--</option> : null}
        {countryCodes.map((code) => {
          return (
            <option key={code} value={code}>
              {allCountries[code]}
            </option>
          );
        })}
      </Field>
    </FieldBase>
  );
};

const StateField: React.FC<any & { country: string }> = ({ country, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error;
  const fieldId = props.id || props.name;
  const allStates = useMemo(() => getCountryStates(country) || {}, [country]);
  const stateCodes: string[] = useMemo(() => Object.keys(allStates), [allStates]);

  // Attempt to match the state by name if it's not part of our choices.
  useEffect(() => {
    if (!field.value || !stateCodes.length) return;
    if (stateCodes.includes(field.value)) return;
    for (const [code, name] of Object.entries(allStates)) {
      if (name.toLowerCase().trim() === field.value.toLowerCase().trim()) {
        helpers.setValue(code);
        break;
      }
    }
  }, [field.value, allStates, stateCodes, helpers]);

  return (
    <FieldBase {...props}>
      {stateCodes.length ? (
        <Field
          as="select"
          id={fieldId}
          className={`w-full bg-white ${!hasError ? 'border-grey-30' : 'border-red'} shadow-item border rounded px-4 py-2`}
          aria-invalid={hasError ? 'true' : null}
          aria-describedby={hasError ? `${fieldId}-error` : null}
          {...field}
          {...props}
        >
          {stateCodes.length > 1 ? <option value="">--</option> : null}
          {stateCodes.map((code) => {
            return (
              <option key={code} value={code}>
                {allStates[code]}
              </option>
            );
          })}
        </Field>
      ) : (
        <input
          className={`w-full ${!hasError ? 'border-grey-30' : 'border-red'} shadow-item border rounded px-4 py-2`}
          aria-invalid={hasError ? 'true' : null}
          aria-describedby={hasError ? `${fieldId}-error` : null}
          type="text"
          disabled={!country}
          {...field}
          {...props}
        />
      )}
    </FieldBase>
  );
};

const makeUserData = (userStore: any, options: RedemptionShippingOptions) => {
  const { info, shipping } = userStore;
  return {
    company: info?.company || '',
    firstname: info?.firstname || '',
    lastname: info?.lastname || '',
    email: info?.email || '',
    phone: info?.phone || '',
    locationname: shipping?.location_name || '',
    locationid: shipping?.location_id || '',
    addressline1: shipping?.address_line_1 || '',
    addressline2: shipping?.address_line_2 || '',
    city: shipping?.city || '',
    postcode: shipping?.postcode || '',
    country: options.can_edit
      ? options?.allowed_countries?.length === 1
        ? options?.allowed_countries[0]
        : shipping?.country || ''
      : shipping?.country || '',
    state: options.use_states ? shipping?.state : '',
  };
};

const ShippingRedeemForm: React.FC<{
  onContinue: () => void;
  redemptions: Redemption[];
  onSubmit: (data: any) => void;
  isLoading?: boolean;
}> = ({ redemptions, ...props }) => {
  const userStore = useUserStore();

  const options = redemptions[0].options!;
  const data = useMemo(() => {
    return makeUserData(userStore, options);
  }, [userStore, options]);

  const schema = useMemo(() => {
    return getShippingRedemptionSchema(options);
  }, [options]); // eslint-disable-line

  if (!options.can_edit) {
    return <ShippingRedeemFormReadonlyInternal options={options} initialData={data} validationSchema={schema} {...props} />;
  }
  return <ShippingRedeemFormInternal options={options} initialData={data} validationSchema={schema} {...props} />;
};

const ShippingRedeemFormInternal: React.FC<{
  options: RedemptionShippingOptions;
  onSubmit: (data: any) => void;
  isLoading?: boolean;
  initialData: ReturnType<typeof makeUserData>;
  validationSchema: ReturnType<typeof getShippingRedemptionSchema>;
}> = ({ options, onSubmit, isLoading, initialData, validationSchema }) => {
  return (
    <div className="max-w-lg w-full">
      <p className="mb-8">{_('redemption:shipping.provideYourDetails')}</p>
      <div className="md:max-w-lg md:mx-auto">
        <Formik initialValues={initialData} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ values }) => (
            <Form>
              {options.required_fields.includes('company') ? <TextField label={_('company')} name="company" /> : null}
              {options.required_fields.includes('firstname') ? <TextField label={_('firstName')} name="firstname" /> : null}
              {options.required_fields.includes('lastname') ? <TextField label={_('lastName')} name="lastname" /> : null}
              {options.required_fields.includes('email') ? <TextField label={_('email')} name="email" type="email" /> : null}
              {options.required_fields.includes('phone') ? <TextField label={_('phone')} name="phone" /> : null}
              {options.required_fields.includes('locationname') ? (
                <TextField label={_('locationName')} name="locationname" />
              ) : null}
              {options.required_fields.includes('locationid') ? <TextField label={_('locationId')} name="locationid" /> : null}
              {options.required_fields.includes('addressline1') ? (
                <>
                  <TextField label={_('address')} name="addressline1" placeholder={_('addressLine1')} />
                  <TextField label="" name="addressline2" placeholder={_('addressLine2')} />
                </>
              ) : null}
              {options.required_fields.includes('city') ? <TextField label={_('city')} name="city" /> : null}
              {options.required_fields.includes('postcode') ? <TextField label={_('zipPostCode')} name="postcode" /> : null}
              {options.required_fields.includes('country') ? (
                <CountryField label={_('country')} name="country" allowedCountries={options?.allowed_countries} />
              ) : null}
              {options.use_states ? <StateField label={_('stateProvince')} name="state" country={values.country} /> : null}
              <div className="mt-4">
                <Button primary isSubmit disabled={isLoading} spin={isLoading}>
                  {_('redemption:sendRequest')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <OrderFootnote options={options} />
    </div>
  );
};

const ShippingRedeemFormReadonlyInternal: React.FC<{
  options: RedemptionShippingOptions;
  onSubmit: (data: any) => void;
  onContinue: () => void;
  isLoading?: boolean;
  initialData: Record<string, string>;
  validationSchema: ReturnType<typeof getShippingRedemptionSchema>;
}> = ({ options, onContinue, onSubmit, isLoading, initialData, validationSchema }) => {
  const [isChanging, setIsChanging] = useState(false);
  const isValid = validationSchema.isValidSync(initialData);
  const canSubmit = !isValid || isLoading;

  if (!isValid) {
    return (
      <div className="max-w-lg w-full mt-8">
        <div className="md:max-w-lg md:mx-auto">
          <div className="space-y-2">
            <p>
              {options.support_email
                ? _('redemption:shipping.notEnoughInfoToAutoFillOrderContactHere', { email: options.support_email })
                : _('redemption:shipping.notEnoughInfoToAutoFillOrder')}
            </p>
            <p>{_('redemption:shipping.dontWorryYouCanReorderLater')}</p>
          </div>
          <div className="mt-8">
            <div className="mt-8 flex justify-between gap-4">
              <Button primary onClick={() => onContinue()}>
                {_('continue')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isChanging) {
    return (
      <div className="max-w-lg w-full mt-8">
        <div className="md:max-w-lg md:mx-auto">
          <div className="space-y-2">
            <p>
              {options.support_email
                ? _('redemption:shipping.contactSupportEmailToUpdateDetails', { email: options.support_email })
                : _('redemption:shipping.contactSupportToUpdateDetails')}
            </p>
            <p>{_('redemption:shipping.dontWorryYouCanReorderLater')}</p>
          </div>
          <div className="mt-8">
            <div className="mt-8 flex justify-between gap-4">
              <Button onClick={() => setIsChanging(false)}>{_('back')}</Button>
              <Button primary onClick={() => onContinue()}>
                {_('continue')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-lg w-full mt-8">
      <div className="md:max-w-lg md:mx-auto">
        <>
          <p className="mb-4">{_('redemption:shipping.confirmYourDetails')}</p>
          <div className="md:max-w-lg md:mx-auto">
            <dl>
              {options.required_fields.includes('company') ? (
                <ConfirmDataField label={_('company')}>{initialData.company || '-'}</ConfirmDataField>
              ) : null}
              {options.required_fields.includes('firstname') ? (
                <ConfirmDataField label={_('firstName')}>{initialData.firstname || '-'}</ConfirmDataField>
              ) : null}
              {options.required_fields.includes('lastname') ? (
                <ConfirmDataField label={_('lastName')}>{initialData.lastname || '-'}</ConfirmDataField>
              ) : null}
              {options.required_fields.includes('email') ? (
                <ConfirmDataField label={_('email')}>{initialData.email || '-'}</ConfirmDataField>
              ) : null}
              {options.required_fields.includes('phone') ? (
                <ConfirmDataField label={_('phone')}>{initialData.phone || '-'}</ConfirmDataField>
              ) : null}
              {options.required_fields.includes('locationname') ? (
                <ConfirmDataField label={_('locationName')}>{initialData.locationname || '-'}</ConfirmDataField>
              ) : null}
              {options.required_fields.includes('locationid') ? (
                <ConfirmDataField label={_('locationId')}>{initialData.locationid || '-'}</ConfirmDataField>
              ) : null}
              {options.required_fields.includes('addressline1') ? (
                <ConfirmDataField label={_('address')}>
                  {initialData.addressline1 || '-'}
                  {initialData.addressline2 ? (
                    <>
                      <br />
                      {initialData.addressline2}
                    </>
                  ) : null}
                </ConfirmDataField>
              ) : null}
              {options.required_fields.includes('city') ? (
                <ConfirmDataField label={_('city')}>{initialData.city || '-'}</ConfirmDataField>
              ) : null}
              {options.required_fields.includes('postcode') ? (
                <ConfirmDataField label={_('zipPostCode')}>{initialData.postcode || '-'}</ConfirmDataField>
              ) : null}
              {options.required_fields.includes('state') ? (
                <ConfirmDataField label={_('stateProvince')}>{initialData.state || '-'}</ConfirmDataField>
              ) : null}
              {options.required_fields.includes('country') ? (
                <ConfirmDataField label={_('country')}>{getCountryList()[initialData.country] || _('unknown')}</ConfirmDataField>
              ) : null}
            </dl>

            <div className="mt-8 flex justify-between gap-4">
              <Button disabled={isLoading} onClick={() => setIsChanging(true)}>
                {_('change')}
              </Button>
              <Button primary disabled={canSubmit} spin={isLoading} onClick={() => onSubmit({})}>
                {_('common:confirm')}
              </Button>
            </div>
          </div>
          <OrderFootnote options={options} />
        </>
      </div>
    </div>
  );
};

export const ConfirmDataField: React.FC<{ label: string }> = ({ label, children }) => {
  return (
    <div className="my-2">
      <dt className="font-medium">{label}</dt>
      <dd>{children}</dd>
    </div>
  );
};

export default ShippingRedeemForm;
