import React from 'react';
import LoadingOverlay from '../components/LoadingOverlay';
import { useLoadingOverlay } from '../state/store';
import { useGenericLoadingMessage } from '../lib/hooks';
import { _ } from '../lib/l18n';

const LoadingOverlayContainer: React.FC<{}> = () => {
  const { opened, id } = useLoadingOverlay();
  const message = useGenericLoadingMessage(id);
  return message ? <LoadingOverlay opened={opened} title={_(message[0])} message={_(message[1])} /> : null;
};

export default LoadingOverlayContainer;
