import React, { useEffect, useState } from 'react';

import CheckmarkIcon from '../assets/icons/checkmark.svg';

const Toast: React.FC<{ message: string }> = ({ message }) => {
  let [classes, setClasses] = useState('');

  useEffect(() => {
    setClasses('toast-appears');
    const t = setTimeout(() => setClasses('toast-disappears'), 3000);
    return () => clearTimeout(t);
  }, []);

  return <StaticToast message={message} className={classes} />;
};

export const StaticToast: React.FC<{ className?: string; message: string }> = ({ className = 'toast-appears', message }) => {
  return (
    <div className={`toast ${className}`} style={{ height: '44px' }} aria-live="assertive">
      <img src={CheckmarkIcon} alt="" className="mx-2" />
      {message}
    </div>
  );
};

export default Toast;
