import localForage from 'localforage';
import { now } from './date';
import { _ } from './l18n';

export type AccessToken = {
  expires: number;
  access_token: string;
  csrf?: string;
};

export interface AuthenticationManager {
  getSavedToken(): Promise<null | AccessToken>;
  getTokenFromSecret(authSecret: string): Promise<AccessToken | null>;
  removeToken(): Promise<void>;
  refreshToken(token: AccessToken): Promise<AccessToken | null>;
  revokeToken(token: AccessToken): Promise<void>;
  saveToken(token: AccessToken): Promise<void>;
  sendAuthenticationEmail(email: string): Promise<void>;
  shouldRefreshToken(token: AccessToken): boolean;
}

export class BasicAuthenticationManager implements AuthenticationManager {
  constructor(protected authApiUrl: string, protected storeUrl: string) {}

  async getSavedToken(): Promise<null | AccessToken> {
    return null;
  }

  async getTokenFromSecret(authSecret: string) {
    try {
      return await this.post<AccessToken>('/auth/access_token', { secret: authSecret }, { credentials: 'include' });
    } catch {}
    return null;
  }

  async refreshToken(token: AccessToken): Promise<AccessToken | null> {
    return null;
  }

  async removeToken() {}

  async revokeToken(token: AccessToken) {
    await this.removeToken();
  }

  async saveToken(token: AccessToken) {}

  async sendAuthenticationEmail(email: string) {
    try {
      await this.post('/auth/email', { email, callback_url: this.storeUrl });
    } catch (err) {
      let message = _('error.sendingAuthEmail');
      if (err instanceof ResponseError) {
        if (err.res.status === 404) {
          message = _('error.noAccountForEmail');
        }
      }
      return Promise.reject(message);
    }
  }

  shouldRefreshToken(token: AccessToken) {
    return Boolean(token.expires && token.expires < now() + 60);
  }

  protected async post<T>(uri: string, data?: {}, options?: {}): Promise<T> {
    const body = data ? JSON.stringify(data) : null;
    const headers = { 'Content-Type': 'application/json; charset=utf-8' };
    return this.request(uri, body, 'POST', headers, options);
  }

  protected async request<T>(uri: string, body?: string | null, method: string = 'GET', headers?: {}, options?: {}): Promise<T> {
    method = method.toUpperCase();
    const url = this.authApiUrl + uri;
    const finalHeaders = { ...headers };
    return fetch(url, {
      method,
      headers: finalHeaders,
      body,
      ...(options || {}),
    })
      .then(async (res) => {
        if (res.ok) {
          if (res.status !== 204) {
            return await res.json();
          }
          return null;
        }
        return Promise.reject(new ResponseError(res));
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export class PersistAuthenticationManager extends BasicAuthenticationManager {
  protected storage: LocalForage;

  constructor(protected authApiUrl: string, protected storeUrl: string) {
    super(authApiUrl, storeUrl);
    this.storage = localForage.createInstance({ name: 'access_token' });
  }

  async getSavedToken() {
    try {
      return await this.storage.getItem<AccessToken>('access_token');
    } catch {}
    return null;
  }

  async getTokenFromSecret(authSecret: string) {
    try {
      return await this.post<AccessToken>(
        '/auth/access_token',
        { secret: authSecret, refresh_method: 'cookie' },
        { credentials: 'include' }
      );
    } catch {}
    return null;
  }

  async refreshToken(token: AccessToken) {
    try {
      return await this.post<AccessToken>('/auth/refresh_token', { csrf: token.csrf }, { credentials: 'include' });
    } catch {}
    return null;
  }

  async removeToken() {
    await this.storage.removeItem('access_token');
  }

  async saveToken(token: AccessToken) {
    try {
      await this.storage.setItem('access_token', token);
    } catch (err) {
      console.log('Could not save access_token', err);
    }
  }
}

class ResponseError extends Error {
  res: Response;
  constructor(res: Response) {
    super('Request error');
    this.res = res;
  }
}
