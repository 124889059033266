import React from 'react';
import { _ } from '../lib/l18n';
import Button from './Button';
import Coins from './Coins';
import { NotificationSuccess } from './Notification';
import NumberSelector from './NumberSelector';
import ProgressBar from './ProgressBar';
import Spinner from './Spinner';
import Tickets from './Tickets';

const Definition: React.FC<{ label: string; className?: string; childrenClassName?: string }> = ({
  label,
  className,
  children,
  childrenClassName,
}) => {
  return (
    <div className={`flex items-center mb-4 ${className || ''}`}>
      <div className="flex-width font-medium w-2/3 pe-4" style={{ maxWidth: '215px' }}>
        {label}
      </div>
      <div className={childrenClassName || ''} style={{ minWidth: '4rem' }}>
        {children}
      </div>
    </div>
  );
};

const Disclaimer: React.FC = ({ children }) => <div className="md:w-64 italic text-grey-slate">{children}</div>;

export const BuyNowForm: React.FC<{
  canAddToCart: boolean;
  canBuy: boolean;
  canChangeQuantity: boolean;
  cost: number;
  endDate: Date;
  quantity: number;
  onQuantityChange: (quantity: number) => void;
  total: number;
  onAddToCart: () => void;
  onBuy: () => void;
}> = ({ canBuy, cost, canAddToCart, canChangeQuantity, endDate, quantity, onQuantityChange, total, onAddToCart, onBuy }) => {
  return (
    <div className="flex flex-col">
      <Definition label={_('costPerItem')} className="font-medium w-64">
        <Coins amount={cost} />
      </Definition>
      <div className="mb-4">
        <NumberSelector value={quantity} onChange={onQuantityChange} disabled={!canChangeQuantity} />
      </div>
      <Definition label={_('total')} className="text-xl font-medium mb-6 w-64">
        <Coins amount={total} />
      </Definition>
      <div className="mb-4 md:w-64">
        <Button disabled={!canAddToCart} onClick={onAddToCart}>
          {_('addToCart')}
        </Button>
      </div>
      <div className="mb-6 md:w-64">
        <Button primary disabled={!canBuy} onClick={onBuy}>
          {_('buyNow')}
        </Button>
      </div>
      <Definition label={_('endsLabel')} className="font-medium mb-6 w-64">
        {endDate.toLocaleDateString()}
      </Definition>
      <Disclaimer>{_('disclaimer.buynow')}</Disclaimer>
    </div>
  );
};

export const AuctionForm: React.FC<{
  canBid: boolean;
  canChangeBid: boolean;
  bid: number;
  currentBid: number;
  bidCount: number;
  handlingFee: number;
  minimumBid: number;
  total: number;
  endDate: Date;
  onBidChange: (bid: number) => void;
  onBid: () => void;
}> = ({ canBid, canChangeBid, bid, currentBid, handlingFee, bidCount, minimumBid, total, endDate, onBid, onBidChange }) => {
  const handleBidChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onBidChange(parseInt(e.target.value, 10) || 0);
  };
  return (
    <div className="flex flex-col">
      {bidCount ? (
        <>
          <Definition
            childrenClassName="font-medium text-sm text-center"
            label={_('currentBid')}
            className="justify-between md:w-64"
          >
            <Coins amount={currentBid} />
          </Definition>
          <Definition
            childrenClassName="font-medium text-sm text-center"
            label={_('numberOfBids')}
            className="justify-between md:w-64"
          >
            {bidCount}
          </Definition>
        </>
      ) : null}
      <Definition childrenClassName="font-medium text-sm text-center" label={_('yourBid')} className="justify-between md:w-64">
        <input
          type="text"
          value={bid.toFixed(0)}
          className="text-sm text-center font-medium rounded border-grey-10 border px-1 py-1"
          style={{ width: '49px', height: '40px' }}
          onChange={handleBidChange}
          disabled={!canChangeBid}
        />
      </Definition>
      <Definition childrenClassName="font-medium text-sm text-center" label={_('bidFee')} className="justify-between md:w-64">
        {handlingFee}
      </Definition>
      <Definition
        childrenClassName="font-medium text-sm text-center"
        label={_('totalCost')}
        className="justify-between md:w-64 font-medium"
      >
        <Coins amount={total} />
      </Definition>
      <Definition childrenClassName="font-medium text-sm text-center" label={_('endsLabel')} className="justify-between md:w-64">
        {endDate.toLocaleDateString()}
      </Definition>
      <div className="mb-6 md:w-64">
        <Button primary disabled={!canBid} onClick={onBid}>
          {_('placeBid')}
        </Button>
      </div>
      <Disclaimer>{_('disclaimer.placebid', { minbid: minimumBid })}</Disclaimer>
    </div>
  );
};

export const RaffleForm: React.FC<{
  canBuy: boolean;
  canChangeQuantity: boolean;
  numberOfEntries: number;
  numberOfWinners: number;
  drawDate: Date;
  cost: number;
  quantityBuying: number;
  total: number;
  onQuantityChange: (quantity: number) => void;
  onBuy: () => void;
}> = ({
  canChangeQuantity,
  numberOfEntries,
  numberOfWinners,
  drawDate,
  cost,
  quantityBuying,
  total,
  onBuy,
  onQuantityChange,
  canBuy,
}) => {
  return (
    <div className="flex flex-col">
      <Definition label={_('raffle.previousEntries')}>{numberOfEntries}</Definition>
      <Definition label={_('raffle.numberWinDraws')}>{_('raffle.quantityToWin', { quantity: numberOfWinners })}</Definition>
      <Definition label={_('raffle.drawDate')}>{drawDate.toLocaleDateString()}</Definition>
      <Definition label={_('raffle.costEntry')} className="font-medium mb-6">
        <Coins amount={cost} />
      </Definition>
      <div className="mb-4">
        <NumberSelector value={quantityBuying} onChange={onQuantityChange} disabled={!canChangeQuantity} />
      </div>
      <Definition label={_('total')} className="mb-6 text-xl font-medium">
        <Coins amount={total} />
      </Definition>
      <div className="mb-6 md:w-64">
        <Button primary onClick={onBuy} disabled={!canBuy}>
          {_('raffle.buyEntry')}
        </Button>
      </div>
      <Disclaimer>{_('disclaimer.buyentry')}</Disclaimer>
    </div>
  );
};

export const SweepstakesForm: React.FC<{
  canBuy: boolean;
  canChangeQuantity: boolean;
  numberOfEntries: number;
  numberOfWinners: number;
  drawDate: Date;
  cost: number;
  quantityBuying: number;
  total: number;
  onQuantityChange: (quantity: number) => void;
  onBuy: () => void;
}> = ({
  canChangeQuantity,
  numberOfEntries,
  numberOfWinners,
  drawDate,
  cost,
  quantityBuying,
  total,
  onBuy,
  onQuantityChange,
  canBuy,
}) => {
  return (
    <div className="flex flex-col">
      <Definition label={_('raffle.previousEntries')}>{numberOfEntries}</Definition>
      <Definition label={_('raffle.numberWinDraws')}>{_('raffle.quantityToWin', { quantity: numberOfWinners })}</Definition>
      <Definition label={_('raffle.drawDate')}>{drawDate.toLocaleDateString()}</Definition>
      {cost > 1 ? (
        <Definition label={_('raffle.costEntry')} className="font-medium mb-6">
          <Tickets amount={cost} />
        </Definition>
      ) : null}
      <div className="mb-4">
        <div className="font-medium mb-4">{_('entries')}</div>
        <NumberSelector value={quantityBuying} onChange={onQuantityChange} disabled={!canChangeQuantity} />
      </div>
      <Definition label={_('total')} className="mb-6 text-xl font-medium">
        <Tickets amount={total} />
      </Definition>
      <div className="mb-6 md:w-64">
        <Button primary onClick={onBuy} disabled={!canBuy}>
          {_('enterDraw')}
        </Button>
      </div>
      <Disclaimer>{_('disclaimer.enterDraw')}</Disclaimer>
    </div>
  );
};

export const ContributionForm: React.FC<{
  canContribute: boolean;
  canChangeAmount: boolean;
  contribQuery: { isLoading: boolean; data?: { amount: number } };
  currencySymbol: null | string;
  currencyValue: null | number;
  amount: number;
  goal: number;
  totalContributed: number;
  endDate: Date;
  onAmountChange: (amount: number) => void;
  onMakeContribution: () => void;
}> = ({
  canChangeAmount,
  amount,
  contribQuery,
  currencySymbol,
  currencyValue,
  endDate,
  goal,
  totalContributed,
  onMakeContribution,
  onAmountChange,
  canContribute,
}) => {
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onAmountChange(parseInt(e.target.value, 10) || 0);
  };
  const progress = totalContributed / goal;

  return (
    <div className="flex flex-col">
      {currencySymbol && currencyValue ? (
        <div className="mb-4 flex items-center">
          <Coins amount={currencyValue} />
          <span>
            <span className="mx-2">=</span>
            {`${currencySymbol}1`}
          </span>
        </div>
      ) : null}

      <div className="mb-4">
        <div className="flex flex-row justify-between mx-1 text-sm mb-1">
          <div className="flex flex-row gap-2 items-center">
            <div className="">{_('contribution.raised')}</div>
            <Coins amount={totalContributed} />
          </div>
          <div className="flex flex-row items-center gap-2">
            <Coins amount={goal} />
            <div className="">{_('contribution.goal')}</div>
          </div>
        </div>
        <ProgressBar progress={progress} showText={false} />
      </div>

      {progress >= 1 ? (
        <div className="mb-4">
          <NotificationSuccess showClose={false}>
            <div className="text-center font-medium">{_('contribution.goalMet')}</div>
          </NotificationSuccess>
        </div>
      ) : null}

      <Definition label={_('contribution.endDate')}>{endDate.toLocaleDateString()}</Definition>
      <Definition label={_('contribution.yourPreviousContributions')}>
        {contribQuery.isLoading ? <Spinner /> : <Coins amount={contribQuery?.data?.amount || 0} />}
      </Definition>
      <Definition label={_('contribution.yourContribution')} className="mb-6 font-medium">
        <input
          type="text"
          value={amount.toFixed(0)}
          className="text-sm text-center font-medium rounded border-grey-10 border px-1 py-1"
          style={{ width: '49px', height: '40px' }}
          onChange={handleAmountChange}
          disabled={!canChangeAmount}
        />
      </Definition>
      <div className="mb-6 md:w-64">
        <Button primary onClick={onMakeContribution} disabled={!canContribute}>
          {_('contribution.makeContribution')}
        </Button>
      </div>
      <Disclaimer>{_('disclaimer.makeContribution')}</Disclaimer>
    </div>
  );
};
