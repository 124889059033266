import React, { useContext, useEffect } from 'react';
import { RootActionsContext } from '../lib/contexts';

const LogoutContainer: React.FC = () => {
  const { performLogout } = useContext(RootActionsContext);
  useEffect(() => {
    performLogout();
  }, [performLogout]);
  return null;
};

export default LogoutContainer;
