import { useQuery } from 'react-query';
import Information from '../components/Information';
import { useRepository } from '../lib/hooks';

const InformationContainer = () => {
  const repository = useRepository();
  const query = useQuery(['account-information'], () => repository.getAccountStoreInformation());

  const isLoading = query.isLoading || query.isError;
  const info = query.data?.info || { type: '', content: '' };
  const rules = query.data?.rules;

  return <Information type={info.type} content={info.content} loading={isLoading} rules={rules} />;
};

export default InformationContainer;
