import React from 'react';

const ContentContainer: React.FC<{ className?: string; style?: React.CSSProperties }> = ({ className = '', style, children }) => {
  return (
    <div className={`container px-6 mx-auto ${className}`} style={style}>
      {children}
    </div>
  );
};

export default ContentContainer;
