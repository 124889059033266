import React from 'react';
import ContentContainer from './layouts/ContentContainer';
import Toast from './Toast';

type ToastType = {
  id: string;
  message: string;
};

const Toaster: React.FC<{ toasts: ToastType[] }> = ({ toasts }) => {
  return (
    <div className="fixed left-0 right-0 bottom-0 pointer-events-none">
      {toasts.map((t) => {
        return (
          <ContentContainer className="flex justify-end mb-6" key={t.id}>
            <Toast message={t.message} />
          </ContentContainer>
        );
      })}
    </div>
  );
};

export default Toaster;
