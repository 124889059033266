import React, { useEffect } from 'react';
import { Redemption } from '../../lib/types';
import Button from '../Button';
import { classNames } from '../../lib/utils';
import { useFormik } from 'formik';
import { _ } from '../../lib/l18n';
import nl2br from 'react-nl2br';

type Props = {
  redemptions: Redemption[];
  onContinue?: () => void;
  onSubmit: (data: any) => void;
  isLoading?: boolean;
  voucher?: {
    code: string;
    information: string;
  };
};

export const VoucherRedeemForm: React.FC<Props> = ({ onSubmit, onContinue, isLoading, redemptions, voucher }) => {
  const { handleSubmit, submitCount, resetForm } = useFormik({
    initialValues: {},
    onSubmit: onSubmit,
  });

  useEffect(() => {
    resetForm();
  }, [resetForm, redemptions]);

  const handleContinue = () => {
    onContinue && onContinue();
  };

  const hasVoucher = !isLoading && Boolean(voucher);

  return (
    <div className="max-w-md">
      <form method="post" onSubmit={handleSubmit}>
        <div className="my-6">
          <div
            className={classNames(
              'border-2 border-grey-30 border-dashed py-2 px-4 text-2xl font-bold flex bg-grey-5 leading-none text-center break-words justify-center break-all',
              !hasVoucher ? 'select-none' : ''
            )}
          >
            <span style={!hasVoucher ? { filter: 'blur(8px)' } : {}}>{voucher?.code || 'FAKEVOUCHER'}</span>
          </div>
        </div>

        {!hasVoucher ? (
          <>
            <div className="flex justify-center">
              <div>
                <Button primary isSubmit disabled={isLoading} spin={isLoading}>
                  {_('redemption:voucher.claimNow')}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="my-6">{_('redemption:voucher.revealInstructions')}</div>

            {onContinue ? (
              <div className="flex justify-center">
                <div className="my-6">
                  <Button onClick={handleContinue} disabled={!submitCount || isLoading}>
                    {_('continue')}
                  </Button>
                </div>
              </div>
            ) : null}

            <div className="my-6 text-sm">{nl2br(voucher?.information ?? '')}</div>
          </>
        )}
      </form>
    </div>
  );
};
