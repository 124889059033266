import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Redemptions from '../components/Redemptions';
import { useRedemptionActivator, useRepository } from '../lib/hooks';
import { Purchase } from '../lib/types';
import { RedemptionFilter } from '../lib/types.local';
import { usePurchasesStore } from '../state/store';

const RedemptionsContainer = () => {
  const params = useParams<{ filter?: RedemptionFilter }>();
  const repository = useRepository();
  const { loading: isLoading, newPurchasesCount, updateLastSeen, purchases } = usePurchasesStore();
  const { redeem } = useRedemptionActivator();

  let filter = params.filter || RedemptionFilter.Pending;
  if (![RedemptionFilter.Completed, RedemptionFilter.Pending].includes(filter)) {
    filter = RedemptionFilter.Pending;
  }

  // When we're loading the pending tab, update the time at which we last saw the redemptions.
  useEffect(() => {
    if (isLoading) return;
    if (filter !== RedemptionFilter.Pending) return;
    updateLastSeen(repository);
  }, [filter, isLoading]); // eslint-disable-line

  if (isLoading) {
    return null;
  }

  const handleRedeem = (purchases: Purchase[]) => {
    redeem(purchases);
  };

  return <Redemptions defaultNewRedemptions={newPurchasesCount} redemptions={purchases} filter={filter} onRedeem={handleRedeem} />;
};

export default RedemptionsContainer;
