import React from 'react';

const ProgressBar: React.FC<{ progress: number; showText?: boolean }> = ({ progress, showText = true }) => {
  let pc = progress > 0 ? Math.max(1, Math.floor(progress * 100)) : 0;
  let width = progress * 100;
  if (width <= 0) {
    width = 0;
  } else if (width < 5) {
    width = 5;
  } else if (width > 95 && width < 100) {
    width = 95;
  } else {
    width = Math.min(100, Math.round(width));
  }
  return (
    <div className="relative pt-1 w-full">
      <div className="overflow-hidden h-6 text-xs text-grey-2 flex rounded-3xl bg-grey-light border-silver-4 border">
        <div
          style={{ minWidth: `${width}%` }}
          className={`flex items-center max-w-full whitespace-nowrap pe-1 ps-2 ${pc ? 'bg-green' : null}`}
        >
          {showText ? `${pc}%` : null}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
