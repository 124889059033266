import * as yup from 'yup';
import { _ } from './l18n';
import { Redemption } from './types';

let yupLocalised = false;

export const getShippingRedemptionSchema = (redemptionOptions: Redemption['options']) => {
  const yup = getYup();
  const options = redemptionOptions ?? { can_edit: true, required_fields: [], use_states: true };

  const items: any = {
    company: yup.string(),
    firstname: yup.string(),
    lastname: yup.string(),
    email: yup.string().email(),
    phone: yup
      .string()
      .default('')
      .matches(/\+?[0-9 .)(-]{8,}/, { message: _('validation:enterPhoneNumber'), excludeEmptyString: true }),
    locationname: yup.string(),
    locationid: yup.string(),
    addressline1: yup.string(),
    addressline2: yup.string().default(''),
    city: yup.string(),
    postcode: yup.string(),
    state: yup.string(),
    country: yup
      .string()
      .default('')
      .matches(/[a-z0-9]{2}/, { excludeEmptyString: true }),
  };

  // Mark the fields as required, if they are.
  Object.keys(items).forEach((key) => {
    if (key === 'addressline2') return; // Never required.
    if (options.required_fields.includes(key)) {
      items[key] = items[key].required();
    }
  });

  return yup.object(items).noUnknown();
};

export const getYup = () => {
  if (!yupLocalised) {
    yup.setLocale({
      mixed: {
        required: _('validation:requiredField'),
      },
      string: {
        email: _('validation:invalidEmailAddress'),
        required: _('validation:requiredField'),
      },
    });
    yupLocalised = true;
  }
  return yup;
};

export const isEmailValid = (email: string) => {
  return Boolean(email.match(/^[^@\s]+@[^@\s]+\.[a-z]{2,}$/i));
};
