import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { _ } from '../../lib/l18n';
import { Redemption } from '../../lib/types';
import Button from '../Button';

type Props = {
  redemptions: Redemption[];
  onContinue?: () => void;
  onSubmit: (data: any) => void;
  isLoading?: boolean;
};

const DownloadRedeemForm: React.FC<Props> = (props) => {
  return <DownloadRedeemFormInternal {...props} />;
};

const DownloadRedeemFormInternal: React.FC<Props> = ({ redemptions, isLoading, onContinue, onSubmit }) => {
  const { handleSubmit, submitCount, resetForm } = useFormik({
    initialValues: {},
    onSubmit: onSubmit,
  });

  useEffect(() => {
    resetForm();
  }, [resetForm, redemptions]);

  const handleContinue = () => {
    onContinue && onContinue();
  };

  return (
    <div className="max-w-md">
      <form method="post" onSubmit={handleSubmit}>
        <input type="hidden" value="<%= returnurl %>" name="returnurl" />

        <div className="flex justify-center">
          <div>
            <Button primary isSubmit disabled={isLoading} spin={isLoading}>
              {_('redemption:download.downloadNow')}
            </Button>
          </div>
        </div>

        <p className="my-2 text-center text-xs text-grey-slate">
          {onContinue ? (
            _('redemption:download.pleaseWaitAfterClick')
          ) : (
            <span dangerouslySetInnerHTML={{ __html: _('redemption:download.pleaseWaitThenContinueWithHtml') }}></span>
          )}
        </p>

        {onContinue ? (
          <div className="flex justify-center">
            <div className="my-6">
              <Button onClick={handleContinue} disabled={!submitCount || isLoading}>
                {_('continue')}
              </Button>
            </div>
          </div>
        ) : null}

        <div>
          <div className="text-sm p-4 bg-grey-light rounded my-6">
            <span dangerouslySetInnerHTML={{ __html: _('redemption:download.noteOneOpportunityWithHtml') }} />
          </div>
        </div>
      </form>
    </div>
  );
};
export default DownloadRedeemForm;
