import React from 'react';
import ReactModal from 'react-modal';

const Modal: React.FC<Omit<ReactModal.Props, 'className' | 'bodyOpenClassName' | 'overlayClassName'>> = ({
  children,
  ...props
}) => {
  return (
    <ReactModal className="modal" bodyOpenClassName="overflow-hidden" overlayClassName="modal-overlay" {...props}>
      {children}
    </ReactModal>
  );
};

export default Modal;
