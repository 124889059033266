import React from 'react';
import { Link } from 'react-router-dom';
import { logoSrc } from '../lib/flavour';
import { useIsEmbedded } from '../lib/hooks';
import { User } from '../lib/types';
import { MenuButton, MenuItemsDesktop, MenuItemsResponsive } from './HeaderMenu';
import ContentContainer from './layouts/ContentContainer';

const Header: React.FC<{
  opened: boolean;
  logo?: string | null;
  balance: number;
  tickets: number;
  level: User['level'];
  newRewards?: number;
  numItemsInCart?: number;
  onOpenChange: () => void;
  onDropdownToggle: (id: string, ref: React.RefObject<HTMLInputElement>) => void;
  openedDropdown?: string;
  pendingRewards?: number;
}> = ({
  balance,
  tickets,
  level,
  logo,
  pendingRewards,
  numItemsInCart,
  opened,
  onOpenChange,
  onDropdownToggle,
  openedDropdown,
}) => {
  const isEmbedded = useIsEmbedded();
  return (
    <header className="bg-grey-light">
      <ContentContainer className="flex justify-between md:justify-start items-center" style={{ height: '61px' }}>
        {!isEmbedded ? (
          <Link to="/" className="flex-1 me-8 h-full flex items-center" style={{ maxWidth: '276px' }}>
            <img src={logo || logoSrc} alt="" className="max-w-full h-full max-h-8" />
          </Link>
        ) : null}
        <MenuItemsDesktop
          coins={balance}
          tickets={tickets}
          level={level}
          rewards={pendingRewards}
          cart={numItemsInCart}
          onDropdownToggle={onDropdownToggle}
          openedDropdown={openedDropdown}
        />
        <div className="md:hidden">
          <MenuButton onClick={onOpenChange} opened={opened} />
        </div>
      </ContentContainer>
      {opened ? (
        <MenuItemsResponsive
          coins={balance}
          tickets={tickets}
          level={level}
          rewards={pendingRewards}
          cart={numItemsInCart}
          onDropdownToggle={onDropdownToggle}
          openedDropdown={openedDropdown}
        />
      ) : null}
    </header>
  );
};

export default Header;
