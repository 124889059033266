import { getCodeList } from 'country-list';
// @ts-ignore
import { UsaStates } from 'usa-states';

const AU_STATES = {
  ACT: 'Australian Capital Territory',
  NSW: 'New South Wales',
  NT: 'Northern Territory',
  QLD: 'Queensland',
  SA: 'South Australia',
  TAS: 'Tasmania',
  VIC: 'Victoria',
  WA: 'Western Australia',
};

const CA_STATES = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NS: 'Nova Scotia',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
};

const MX_STATES = {
  AG: 'Aguascalientes',
  BC: 'Baja California Norte',
  BS: 'Baja California Sur',
  CH: 'Chihuahua',
  CL: 'Colima',
  CM: 'Campeche',
  CO: 'Coahuila',
  CS: 'Chiapas',
  DF: 'Distrito Federal',
  DU: 'Durango',
  GR: 'Guerrero',
  GJ: 'Guanajuato',
  HI: 'Hidalgo',
  JA: 'Jalisco',
  MX: 'México',
  MI: 'Michoacan',
  MO: 'Morelos',
  NY: 'Nayarit',
  NL: 'Nuevo Leon',
  OX: 'Oaxaca',
  PB: 'Puebla',
  QR: 'Quintana Roo',
  QT: 'Queretaro',
  SI: 'Sinaloa',
  SP: 'San Luis Potosi',
  SO: 'Sonora',
  TB: 'Tabasco',
  TL: 'Tlaxcala',
  TM: 'Tamaulipas',
  VC: 'Veracruz',
  YU: 'Yucatan',
  ZA: 'Zacatecas',
};

const NZ_REGIONS_FLAT = [
  'Auckland',
  'Bay of Plenty',
  'Canterbury',
  'Gisborne',
  "Hawke's Bay",
  'Manawatū-Whanganui',
  'Marlborough',
  'Nelson',
  'Northland',
  'Otago',
  'Southland',
  'Taranaki',
  'Tasman',
  'Waikato',
  'Wellington',
  'West Coast',
];

const CN_REGIONS_FLAT = [
  '河北省',
  '山西省',
  '辽宁省',
  '吉林省',
  '黑龙江省',
  '江苏省',
  '浙江省',
  '安徽省',
  '福建省',
  '江西省',
  '山东省',
  '河南省',
  '湖北省',
  '湖南省',
  '广东省',
  '海南省',
  '四川省',
  '贵州省',
  '云南省',
  '陕西省',
  '甘肃省',
  '青海省',
  '内蒙古',
  '广西',
  '西藏',
  '宁夏',
  '新疆',
  '北京',
  '天津',
  '上海',
  '重庆',
  '香港',
  '澳门',
  '台湾',
];

let allCountriesSortedByName: { [index: string]: string };

export function getCountryList() {
  if (typeof allCountriesSortedByName === 'undefined') {
    const allCountries = getCodeList();
    allCountriesSortedByName = Object.keys(allCountries)
      .map((code) => {
        return [code, allCountries[code]];
      })
      .sort((c1, c2) => {
        return c1[1].localeCompare(c2[1]);
      })
      .reduce<{ [index: string]: string }>((carry, c) => ({ ...carry, [c[0]]: c[1] }), {});
  }
  return allCountriesSortedByName;
}

export function getCountryStates(code: string): { [index: string]: string } | null {
  switch (code) {
    case 'au':
      return AU_STATES;
    case 'ca':
      return CA_STATES;
    case 'cn':
      return getChinaStates();
    case 'mx':
      return MX_STATES;
    case 'nz':
      return getNzStates();
    case 'us':
      return getUsStates();
  }
  return null;
}

export function getCountriesWithStateList() {
  return ['au', 'ca', 'cn', 'mx', 'nz', 'us'];
}

let allChinaStates: { [index: string]: string };

function getChinaStates() {
  if (typeof allChinaStates === 'undefined') {
    allChinaStates = CN_REGIONS_FLAT.reduce((carry, state) => ({ ...carry, [state]: state }), {});
  }
  return allChinaStates;
}

let allNzStates: { [index: string]: string };

function getNzStates() {
  if (typeof allNzStates === 'undefined') {
    allNzStates = NZ_REGIONS_FLAT.reduce((carry, state) => ({ ...carry, [state]: state }), {});
  }
  return allNzStates;
}

let allUsStates: { [index: string]: string };

function getUsStates() {
  if (typeof allUsStates === 'undefined') {
    allUsStates = new UsaStates({
      contiguousOnly: false,
      includeTerritories: true,
      exclude: [],
      ignoreCharacter: '',
    }).states.reduce<{ [index: string]: string }>((carry: {}, state: any) => {
      return {
        ...carry,
        [state.abbreviation]: state.name,
      };
    }, {});
  }
  return allUsStates;
}
