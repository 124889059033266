import Coins from '../components/Coins';
import Tickets from '../components/Tickets';
import { isFuture, isPast } from './date';
import { DrawEntry, Item, ItemType } from './types';
import { CurrencyComponent, Product } from './types.local';

export const hasUnlimitedStock = (item: Item | Product) => {
  return !item.quantity;
};

export const getRemainingStock = (item: Item) => {
  return hasUnlimitedStock(item) ? 999 : Math.max(0, item.quantity - item.purchased);
};

export const getCurrencyComponent = (product: Product | Item): CurrencyComponent => {
  if (product.type === ItemType.Sweepstakes) {
    return Tickets;
  }
  return Coins;
};

export const getItemFromProduct = (product: Product, itemId?: string) => {
  let item;

  if (!itemId) {
    itemId = product.item ? product.item.id : product.variants[0].id;
  }

  if (product.item) {
    item = product.item;
  } else {
    item = product.variants.find((i) => i.id === itemId)?.item;
  }

  if (!item || item.id !== itemId) {
    throw new Error('Item not found on product');
  }

  return item;
};

export const getHighestBid = (item: Item) => {
  if (!item.auction) return 0;
  return !item.auction.bid ? item.cost : item.auction.bid;
};

export const getMinBid = (item: Item) => {
  if (!item.auction) return 0;
  return !item.auction.bid ? item.cost : item.auction.bid + item.auction.bid_increment;
};

export const getNumberOfBids = (item: Item) => {
  if (!item.auction) return 0;
  return item.auction.count;
};

const getDrawEntries = (item: Item) => {
  let entries: DrawEntry[] = [];
  if (item.type === ItemType.Sweepstakes) {
    entries = item.sweepstakes?.tickets || [];
  } else if (item.type === ItemType.Raffle) {
    entries = item.raffle?.tickets || [];
  }
  return entries;
};

export const getMyEntries = (item: Item, userId: string) => {
  return getDrawEntries(item)
    .filter((e) => e.user_id === userId)
    .reduce((c, e) => c + e.quantity, 0);
};

export const getNumberOfEntries = (item: Item) => {
  return getDrawEntries(item).reduce((c, e) => c + e.quantity, 0);
};

export const getChanceOfWinning = (item: Item, userId: string) => {
  const myEntries = getMyEntries(item, userId);
  const totalEntries = getNumberOfEntries(item);
  return getWinningChance(item.quantity, myEntries, totalEntries);
};

export const isItemFromProduct = (product: Product, itemId: string) => {
  let item;
  try {
    item = getItemFromProduct(product, itemId);
  } catch {
    return false;
  }
  return Boolean(item);
};

export const isAvailable = (item: Item | Product) => {
  return isPast(item.available_from * 1000) && isFuture(item.available_until * 1000);
};

export function itemsToProduct(items: Item[]): Product {
  return itemsToProducts(items)[0];
}

export function itemsToProducts(items: Item[]): Product[] {
  const groupedItems = items.reduce<{ [index: string]: Item[] }>((carry, item) => {
    const id = item.product ? item.product.id : item.id;
    return {
      ...carry,
      [id]: (carry[id] || []).concat(item),
    };
  }, {});

  return Object.values(groupedItems).map((items) => {
    const refItem = items[0];
    const totalQty = Math.max(
      items.reduce((c, i) => (!i.quantity || c === 0 ? 0 : Math.max(0, c) + i.quantity), -1),
      0
    ); // 0 is any is unlimited, we start with -1 to identify if any item is unlimited.
    const totalPurchased = items.reduce((c, i) => (!i.quantity ? c : c + i.purchased), 0);

    const data = {
      id: refItem.product ? refItem.product.id : refItem.id,
      type: refItem.type,
      available_from: refItem.available_from,
      available_until: refItem.available_until,
      cost: refItem.type === ItemType.Auction ? getHighestBid(refItem) : refItem.cost,
      description: refItem.description,
      name: refItem.product ? refItem.product.name : refItem.name,
      quantity: totalQty,
      remaining_stock: Math.max(0, totalQty ? totalQty - totalPurchased : 999),
      image_url: refItem.image_url,
      thumbnail_url: refItem.thumbnail_url,
    };

    let product;
    if (items.length > 1) {
      product = {
        ...data,
        item: null,
        variants: items
          .sort((i1, i2) => (i1.product && i2.product ? i1.product.order - i2.product.order : 0))
          .map((i) => ({
            id: i.id,
            name: i.product ? i.product.variant : i.name,
            remaining_stock: i.quantity ? Math.max(0, i.quantity - i.purchased) : 999,
            item: i,
          })),
      };
    } else {
      product = {
        ...data,
        item: refItem,
        variants: null,
      };
    }

    return product;
  });
}

// Credit: https://rosettacode.org/wiki/Evaluate_binomial_coefficients#JavaScript
function binom(n: number, k: number) {
  var coeff = 1;
  var i;

  if (k < 0 || k > n) return 0;

  for (i = 0; i < k; i++) {
    coeff = (coeff * (n - i)) / (i + 1);
  }

  return coeff;
}

// Credit: https://math.stackexchange.com/a/92008
function getWinningChance(totalItems: number, myEntries: number, totalEntries: number) {
  if (totalEntries <= 0 || myEntries <= 0) {
    return 0;
  }
  const lost = binom(totalEntries - myEntries, totalItems);
  const total = binom(totalEntries, totalItems);
  if (lost <= 0) {
    return 1;
  }
  return 1 - lost / total;
}
