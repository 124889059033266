import React from 'react';
import { _ } from '../lib/l18n';

import { useBrandingStore } from '../state/store';

const DoubleTicketIcon = (props: React.HTMLAttributes<SVGElement>) => {
  return (
    <svg width="41" height="24" viewBox="0 0 41 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.1505 0V5.83785H0V12.9185L0.50475 13.0439C1.39775 13.2669 2.0216 14.0378 2.0216 14.9189C2.0216 15.8 1.39775 16.571 0.50475 16.7939L0 16.9194V24H33.0195V18.1621H40.17V0H7.1505Z"
        fill="#D3D3D3"
      />
      <path
        d="M7.1505 0V5.83785H0V12.9185L0.50475 13.0439C1.39775 13.2669 2.0216 14.0378 2.0216 14.9189C2.0216 15.8 1.39775 16.571 0.50475 16.7939L0 16.9194V24H33.0195V18.1621H40.17V0H7.1505ZM31.6718 22.7027H1.34774V17.8822C2.56057 17.3735 3.36934 16.2181 3.36934 14.9189C3.36934 13.6197 2.56057 12.4643 1.34774 11.9557V7.13515H31.6718V11.9557C30.4589 12.4643 29.6502 13.6197 29.6502 14.9189C29.6502 16.2181 30.4589 17.3735 31.6718 17.8822V22.7027ZM38.8223 16.8648H32.8002L32.5148 16.7939C31.6217 16.571 30.9979 15.8 30.9979 14.9189C30.9979 14.0378 31.6217 13.2669 32.5148 13.0439L33.0195 12.9185V5.83785H8.49824V1.2973H38.8223V16.8648Z"
        fill="#6C6C6C"
      />
      <path
        d="M16.5098 11.9893L17.3298 13.5887L17.5715 14.0601L18.112 14.1357L19.9457 14.3922L18.6188 15.6371L18.2277 16.0041L18.3201 16.5222L18.6333 18.2801L16.9932 17.4501L16.5098 17.2055L16.0264 17.4501L14.3863 18.2801L14.6995 16.5222L14.7919 16.0041L14.4008 15.6371L13.0739 14.3922L14.9076 14.1357L15.4481 14.0601L15.6898 13.5887L16.5098 11.9893ZM16.5098 9.72975L14.7582 13.1461L10.8414 13.6939L13.6756 16.3532L13.0066 20.1081L16.5098 18.3353L20.013 20.1081L19.344 16.3532L22.1782 13.6939L18.2614 13.1461L16.5098 9.72975Z"
        fill="#6C6C6C"
      />
    </svg>
  );
};

export const TicketsIcon: React.FC<{ className?: string }> = ({ className }) => {
  const { tickets_icon } = useBrandingStore();
  if (tickets_icon) {
    return <img src={tickets_icon} alt={_('tickets')} className={`h-6  w-auto ${className || ''}`} />;
  }
  return <DoubleTicketIcon className={`h-6  w-auto ${className}`} aria-label={_('tickets')} />;
};
