import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../components/Button';
import ContentContainer from '../components/layouts/ContentContainer';
import { _ } from '../lib/l18n';

const PurchaseThankYou = () => {
  const history = useHistory();
  return (
    <ContentContainer className="flex justify-center">
      <div className="max-w-sm mt-16">
        <h1 className="text-xl mb-8 text-center">{_('thankYou')}</h1>
        <p className="mb-4">{_('redemption:redemptionReceivedCheckNotifications')}</p>
        <Button primary onClick={() => history.push('/')}>
          {_('continue')}
        </Button>
      </div>
    </ContentContainer>
  );
};

const PurchaseThankYouContainer = () => {
  return <PurchaseThankYou />;
};

export default PurchaseThankYouContainer;
