import { CurrencyComponent } from '../lib/types.local';
import CoinsIcon from './CoinsIcon';

const Coins: CurrencyComponent = ({ amount, className }) => {
  return (
    <div className={`flex items-center gap-2 ${className || ''}`}>
      <CoinsIcon />
      {amount.toLocaleString()}
    </div>
  );
};

export default Coins;
