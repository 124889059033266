import React from 'react';
import Toaster from '../components/Toaster';
import { useAlertStore } from '../state/store';

const ToasterContainer = () => {
  const toasts = useAlertStore((state) => state.toasts);
  return <Toaster toasts={toasts} />;
};

export default ToasterContainer;
