import React from 'react';
import { NavLink } from 'react-router-dom';
import ContentContainer from './ContentContainer';

export const SideMenuItem: React.FC<{ isActive?: (match: any, location: any) => boolean; to: string }> = ({
  isActive,
  to,
  children,
}) => {
  return (
    <div className="mb-4">
      <NavLink isActive={isActive} className="text-grey" activeClassName="underline" to={to}>
        {children}
      </NavLink>
    </div>
  );
};

const PageWithSideMenu: React.FC<{
  renderMenuItems: () => React.ReactNode;
  menuTitle: string;
}> = ({ menuTitle, renderMenuItems, children }) => {
  return (
    <ContentContainer className="md:mt-8">
      <div className="flex flex-col md:flex-row">
        <div className="md:mr-10 md:w-44 lg:w-64 lg:flex-width mb-6 hidden md:block">
          <h1 className="font-medium mt-2 mb-6">{menuTitle}</h1>
          {renderMenuItems()}
        </div>
        <div className="flex-1">{children}</div>
      </div>
    </ContentContainer>
  );
};

export default PageWithSideMenu;
