import './lib/polyfills';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Modal from 'react-modal';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { init as initL18n } from './lib/l18n';
import { AppContext } from './lib/contexts';

const rootNode = document.getElementById('root') as HTMLElement;

Modal.setAppElement(rootNode);

const I18Loader: React.FC = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    (async () => {
      await initL18n();
      setLoaded(true);
    })();
  }, []);
  return <>{loaded ? children : null}</>;
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <I18Loader>
        <AppContext.Provider value={{ embedded: window.parent !== window }}>
          <App />
        </AppContext.Provider>
      </I18Loader>
    </Router>
  </React.StrictMode>,
  rootNode
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
