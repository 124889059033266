import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIsEmbedded, useRepository } from '../../lib/hooks';
import { storeUrl } from '../../lib/urls';
import { usePurchasesStore } from '../../state/store';

const ExternalRedeemRedirect: React.FC<{ url?: string; onRedirect?: () => void }> = ({ url, onRedirect }) => {
  const history = useHistory();
  const repo = useRepository();
  const { loadPurchases } = usePurchasesStore();
  const isEmbedded = useIsEmbedded();
  const [hasRedirected, setRedirected] = useState(false);

  // Whenever the URL changes, we reset the redirect flag.
  useEffect(() => {
    setRedirected(false);
  }, [url, setRedirected]);

  // Perform the redirect.
  useEffect(() => {
    if (!url || hasRedirected) return;

    // Perform the redirect.
    if (isEmbedded) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }

    setRedirected(true);
  }, [url, hasRedirected]); // eslint-disable-line

  // Observe the focus to trigger an update of the purchases.
  useEffect(() => {
    const fn = () => {
      if (hasRedirected && isEmbedded) {
        onRedirect && onRedirect();
        loadPurchases(repo);
        history.push(storeUrl);
      }
    };
    window.addEventListener('focus', fn);
    return () => document.removeEventListener('focus', fn);
  });

  return null;
};

export default ExternalRedeemRedirect;
