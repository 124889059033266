import React from 'react';
import { _ } from '../lib/l18n';
import Coins from './Coins';

const Definition: React.FC<{ label: string; className?: string }> = ({ label, children, className }) => {
  return (
    <div className={`flex text-xl mb-5 last:mb-0 ${className || ''}`}>
      <div className="flex-1 font-medium" style={{ maxWidth: '150px' }}>
        {label}
      </div>
      <div className="flex-1">{children}</div>
    </div>
  );
};

const Divider: React.FC<{ className?: string }> = ({ className }) => (
  <div className={`-mx-4 md:mx-0 my-6 border-b border-blue-cloudy ${className || ''}`}></div>
);

export const ActivityItem: React.FC<{ date: string; amount: number; label: string; value: string }> = ({
  date,
  amount,
  label,
  value,
}) => {
  return (
    <>
      <Definition label={_('amount')} className="font-medium">
        <Coins amount={amount} />
      </Definition>
      <Definition label={label}>{value}</Definition>
      <Definition label={_('date')}>{date}</Definition>
      <Divider />
    </>
  );
};

export const ActivityItemLst: React.FC = ({ children }) => {
  return <div>{children}</div>;
};

const Activity: React.FC = ({ children }) => {
  return (
    <div>
      <h1 className="text-xl font-medium">{_('profile')}</h1>
      <Divider className="mt-3" />
      {children}
    </div>
  );
};

export default Activity;
