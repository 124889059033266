import React from 'react';

import MinusIcon from '../assets/icons/minus.svg';
import MinusHoverIcon from '../assets/icons/minus-hover.svg';
import PlusIcon from '../assets/icons/plus.svg';
import PlusHoverIcon from '../assets/icons/plus-hover.svg';
import { _ } from '../lib/l18n';

const NumberSelector: React.FC<{ value: number; onChange: (n: number) => void; disabled?: boolean }> = ({
  value,
  onChange,
  disabled = false,
}) => {
  const handleQtyChange = (qty: number) => {
    if (disabled) return;
    onChange(qty);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(parseInt(e.target.value, 10) || 0);
  };
  let classes = 'rounded border-silver-4 flex border p2';
  let btnClasses = 'group outline-none px-3 focus:outline-none';
  if (disabled) {
    classes += ' bg-silver-3 border-none';
    btnClasses = btnClasses.replace('group', '');
  }
  return (
    <div className="flex">
      <div className={classes} style={{ width: '118px', height: '40px' }}>
        <div className="flex justify-center">
          <button className={btnClasses} onClick={(e) => handleQtyChange(value - 1)}>
            <img src={MinusIcon} alt={_('removeOne')} className="group-hover:hidden group-focus:hidden" />
            <img src={MinusHoverIcon} alt={_('removeOne')} className="hidden group-hover:block group-focus:block" />
          </button>
        </div>
        <div className="flex flex-1 justify-center">
          <input
            type="text"
            value={value.toFixed(0)}
            onChange={handleChange}
            className={`text-center w-full ${disabled ? 'bg-silver-3' : ''}`}
            disabled={disabled}
          />
        </div>
        <div className="flex justify-center">
          <button className={btnClasses} onClick={(e) => handleQtyChange(value + 1)}>
            <img src={PlusIcon} alt={_('addOne')} className="group-hover:hidden group-focus:hidden" />
            <img src={PlusHoverIcon} alt={_('addOne')} className="hidden group-hover:block group-focus:block" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NumberSelector;
