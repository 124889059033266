import React from 'react';
import ContentContainer from './ContentContainer';

const BlankPage: React.FC<{}> = ({ children }) => {
  return (
    <ContentContainer className="flex flex-1">
      <div className="flex flex-1">{children}</div>
    </ContentContainer>
  );
};

export default BlankPage;
