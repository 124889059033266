import jwt from 'jsonwebtoken';
import localForage from 'localforage';
import parseLinkHeader from 'parse-link-header';
import queryString from 'query-string';
import { AccessToken, AuthenticationManager } from './auth';
import { now } from './date';
import { itemsToProducts } from './item';
import {
  AccountStoreInformation,
  Branding,
  ContributionEntry,
  Item,
  ItemType,
  LeaderboardSectionEntry,
  LeaderboardUserEntry,
  Purchase,
  PurchaseState,
  Redemption,
  SectionInformation,
  Team,
  TeamMarket,
  User,
  UserTransaction,
  WithBranding,
  WithLeaderboards,
} from './types';
import { Product } from './types.local';

export const REQUEST_SUCCESS = 'request-success';
export const AUTHENTICATION_ERROR = 'AuthenticationError';
export const BAD_REQUEST_ERROR = 'BadRequestError';
export const NETWORK_ERROR = 'NetworkError';
export const NOT_FOUND_ERROR = 'NotFoundError';
export const PERMISSION_ERROR = 'PermissionError';
export const FAILED_DEPENDENCY_ERROR = 'FailedDependencyError';
export const SERVER_ERROR = 'ServerError';
export const UNKNOWN_ERROR = 'UnknownError';

class RequestError {
  name = 'RequestError';
  constructor(public message: string = '', public code: string = 'UNCLASSIFIED_ERROR') {}
  toString() {
    return `${this.name}: ${this.code} ${this.message}`;
  }
}
class AuthenticationError extends RequestError {
  name = AUTHENTICATION_ERROR;
}
class BadRequestError extends RequestError {
  name = BAD_REQUEST_ERROR;
}
class NetworkError extends RequestError {
  name = NETWORK_ERROR;
}
class NotFoundError extends RequestError {
  name = NOT_FOUND_ERROR;
}
class PermissionError extends RequestError {
  name = PERMISSION_ERROR;
}
class FailedDependencyError extends RequestError {
  name = FAILED_DEPENDENCY_ERROR;
}
class ServerError extends RequestError {
  name = SERVER_ERROR;
}
class UnknownError extends RequestError {
  name = UNKNOWN_ERROR;
}

type ListenerCallback = (...args: any[]) => void;

/**
 * Repository interface.
 */
export interface Repository {
  getAccountStoreInformation(): Promise<AccountStoreInformation>;
  getBalance(): Promise<Pick<User, 'coins' | 'tickets'>>;
  getBranding(): Promise<Branding>;
  getContributionEntry(itemId: string, userId: string): Promise<ContributionEntry>;
  getLastSeenPurchases(): Promise<Date>;
  getLeaderboard(): Promise<LeaderboardUserEntry[]>;
  getSvsLeaderboard(): Promise<LeaderboardSectionEntry[]>;
  getMe(): Promise<User>;
  getMetaRedemption(purchaseIds: string[], returnUrl?: string): Promise<{ url: string; redemptions: Redemption[] }>;
  getMetaRedemptionUrl(purchaseIds: string[], returnUrl?: string): Promise<string>;
  getProducts(excludeTypes?: ItemType[]): Promise<Product[]>;
  getPurchase(id: string): Promise<Purchase>;
  getPurchases(): Promise<Purchase[]>;
  getRecentActivity(): Promise<UserTransaction[]>;
  getRedemption(purchaseId: string, returnUrl?: string): Promise<Redemption>;
  getRedemptionFile(redemptionId: string): Promise<{ url: string; name: string }>;
  getRedemptionVoucher(redemptionId: string): Promise<{ code: string; information: string }>;
  getRedemptionUrl(purchaseId: string, returnUrl?: string): Promise<string>;
  getTeam(): Promise<Team & WithBranding & WithLeaderboards>;
  getTeamMarket(): Promise<TeamMarket>;
  makeContribution(itemId: string, amount: number): Promise<ContributionEntry>;
  placeBid(itemId: string, bid: number): Promise<void>;
  purchaseItem(itemId: string, quantity: number): Promise<Purchase[]>;
  purchaseItems(items: { item_id: string; quantity?: number }[]): Promise<Purchase[]>;
  purchaseRaffleTicket(itemId: string, quantity: number): Promise<void>;
  setLastSeenPurchases(date: Date): Promise<void>;
  submitMetaRedemption(redemptionIds: string[], data: any): Promise<{ message: string } | undefined>;
  submitRedemption(redemptionId: string, data: any): Promise<{ message: string } | undefined>;

  /** @deprecated Use getAccountStoreInformation instead. */
  getSectionInformation(): Promise<SectionInformation>;
  /** @deprecated Use getTeam instead. */
  isLeaderboardEnabled(): Promise<boolean>;
  /** @deprecated Use getTeam instead. */
  isSvsLeaderboardEnabled(): Promise<boolean>;
}

/**
 * Dummy repository which does nothing.
 */
export class DummyRepository implements Repository {
  getAccountStoreInformation() {
    return Promise.reject();
  }
  getBalance() {
    return Promise.resolve({ coins: 0, tickets: 0 });
  }
  getBranding() {
    return Promise.resolve({ logo: null, icon_double: null, tickets_icon: null });
  }
  getContributionEntry(itemId: string, userId: string) {
    return Promise.resolve({ user_id: userId, item_id: itemId, amount: 0 });
  }
  async getLastSeenPurchases() {
    return new Date(0);
  }
  getLeaderboard() {
    return Promise.resolve([] as LeaderboardUserEntry[]);
  }
  getSvsLeaderboard() {
    return Promise.resolve([] as LeaderboardSectionEntry[]);
  }
  getMe() {
    return Promise.resolve({
      id: 'abc123',
      team_id: 'def123',
      account_id: 'xyz123',
      coins: 0,
      tickets: 0,
      level: {
        number: 1,
        badge_url: '',
        level_at: 0,
        next_level_at: null,
      },
      firstname: 'Bob',
      lastname: 'Dylan',
      shipping: null,
    });
  }
  getMetaRedemption(purchaseIds: string[], returnUrl?: string): Promise<{ url: string; redemptions: Redemption[] }> {
    return Promise.reject();
  }
  getMetaRedemptionUrl(purchaseIds: string[], returnUrl?: string): Promise<string> {
    return Promise.reject();
  }
  getProducts() {
    return Promise.resolve([] as Product[]);
  }
  getPurchase(id: string) {
    return Promise.reject();
  }
  getPurchases() {
    return Promise.resolve([] as Purchase[]);
  }
  getRecentActivity() {
    return Promise.resolve([] as UserTransaction[]);
  }
  getRedemption(purchaseId: string, returnUrl?: string): Promise<Redemption> {
    return Promise.reject();
  }
  getRedemptionFile(redemptionId: string): Promise<{ url: string; name: string }> {
    return Promise.reject();
  }
  getRedemptionVoucher(redemptionId: string) {
    return Promise.reject();
  }
  getRedemptionUrl(purchaseId: string, returnUrl?: string): Promise<string> {
    return Promise.reject();
  }
  getSectionInformation(): Promise<SectionInformation> {
    return Promise.reject();
  }
  getTeam() {
    return Promise.reject();
  }
  getTeamMarket() {
    return Promise.reject();
  }
  isLeaderboardEnabled() {
    return Promise.resolve(true);
  }
  isSvsLeaderboardEnabled() {
    return Promise.resolve(true);
  }
  makeContribution(itemId: string, amount: number) {
    return Promise.reject();
  }
  placeBid(itemId: string, bid: number) {
    return Promise.resolve();
  }
  purchaseItem(itemId: string, quantity: number): Promise<Purchase[]> {
    return Promise.reject();
  }
  purchaseItems(items: { item_id: string; quantity?: number }[]): Promise<Purchase[]> {
    return Promise.reject();
  }
  purchaseRaffleTicket(itemId: string, quantity: number) {
    return Promise.resolve();
  }
  async setLastSeenPurchases(d: Date) {}
  submitMetaRedemption(redemptionIds: string[], data: any) {
    return Promise.reject();
  }
  submitRedemption(redemptionId: string, data: any) {
    return Promise.reject();
  }
}

/**
 * User repository requiring user information to be initiated.
 */
export class UserRepository implements Repository {
  protected apiUrl: string;
  protected userId: string;
  protected sectionId: string;
  protected accountId: string;
  protected persistor: LocalForage;
  protected listeners: { [index: string]: ListenerCallback[] } = {};

  constructor(apiUrl: string, userId: string, sectionId: string, accountId: string) {
    this.apiUrl = apiUrl;
    this.userId = userId;
    this.sectionId = sectionId;
    this.accountId = accountId;
    this.persistor = localForage.createInstance({
      name: 'repo-user',
      storeName: 'usr_' + userId.replace(/[^a-z0-9_]/gi, '_'),
    });
    this.listeners = {};
  }

  addListener(type: string, callback: ListenerCallback) {
    this.listeners[type] = this.listeners[type] || [];
    this.listeners[type].push(callback);
  }

  notifyListeners(type: string, ...args: any[]) {
    const listeners = this.listeners[type] || [];
    listeners.forEach((cb) => {
      cb(...args);
    });
  }

  removeListener(type: string, callback: ListenerCallback) {
    this.listeners[type] = this.listeners[type].filter((c) => c !== callback);
  }

  async getBearerToken(): Promise<string> {
    throw new Error('Unknown Bearer token');
  }

  async getAccountStoreInformation() {
    return await this.get<AccountStoreInformation>(`/v2/accounts/${this.accountId}/store_information`);
  }

  async getBalance() {
    return await this.get<{ coins: number; tickets: number }>(`/v2/users/${this.userId}/balance`);
  }

  async getBranding() {
    return await this.get<Branding>(`/v2/accounts/${this.accountId}/branding`);
  }

  async getContributionEntry(itemId: string, userId: string) {
    return await this.get<ContributionEntry>(`/items/${itemId}/contributions/${userId}`);
  }

  async getItem(itemId: string): Promise<Item> {
    return this.get<Item>(`/items/${itemId}`);
  }

  async getSectionInformation(): Promise<SectionInformation> {
    return this.get<SectionInformation>(`/sections/${this.sectionId}/information`);
  }

  async getLastSeenPurchases(): Promise<Date> {
    let lastSeen: number = 0;
    try {
      lastSeen = (await this.persistor.getItem('last_seen_purchases')) || 0;
    } catch {}
    return new Date(lastSeen);
  }

  async getLeaderboard() {
    return this.get<LeaderboardUserEntry[]>(`/sections/${this.sectionId}/leaderboard`);
  }

  async getSvsLeaderboard() {
    return this.get<LeaderboardSectionEntry[]>(`/accounts/${this.accountId}/leaderboard`);
  }

  async getMe() {
    return this.get<User>(`/v2/users/${this.userId}`);
  }

  async getMetaRedemption(purchaseIds: string[], returnUrl?: string): Promise<{ url: string; redemptions: Redemption[] }> {
    return await this.post<{ url: string; redemptions: Redemption[] }>(`/users/${this.userId}/redemptions`, {
      returnurl: returnUrl,
      purchase_ids: purchaseIds,
    });
  }

  async getMetaRedemptionUrl(purchaseIds: string[], returnUrl?: string) {
    if (purchaseIds.length === 1) {
      return this.getRedemptionUrl(purchaseIds[0], returnUrl);
    }
    return (await this.getMetaRedemption(purchaseIds, returnUrl)).url;
  }

  async getProducts(excludeTypes: ItemType[] = []) {
    const allTypes = [ItemType.Purchase, ItemType.Auction, ItemType.Raffle, ItemType.Contribution, ItemType.Sweepstakes];
    const data = await this.get<Item[]>(`/v2/teams/${this.sectionId}/items`, {
      item_types: allTypes.filter((t) => !excludeTypes.includes(t)),
    });
    return itemsToProducts(data);
  }

  async getPurchase(id: string) {
    return await this.get<Purchase>(`/purchases/${id}`);
  }

  async getPurchases() {
    return await this.get<Purchase[]>(`/users/${this.userId}/purchases`);
  }

  async getRecentActivity() {
    return await this.get<UserTransaction[]>(`/users/${this.userId}/transactions`);
  }

  async getRedemption(purchaseId: string, returnUrl?: string): Promise<Redemption> {
    return await this.get<Redemption>(`/purchases/${purchaseId}/redemption`, { returnurl: returnUrl });
  }

  async getRedemptionFile(redemptionId: string): Promise<{ url: string; name: string }> {
    return await this.post<{ url: string; name: string }>(`/redemptions/${redemptionId}/file`);
  }

  async getRedemptionVoucher(redemptionId: string) {
    return await this.post<{ code: string; information: string }>(`/v2/redemptions/${redemptionId}/voucher`);
  }

  async getRedemptionUrl(purchaseId: string, returnUrl?: string): Promise<string> {
    const redemption = await this.getRedemption(purchaseId, returnUrl);
    if (!redemption.url) throw new Error('Could not find redemption URL');
    return redemption.url;
  }

  async getTeam() {
    return this.get<Team & WithBranding & WithLeaderboards>(`/v2/teams/${this.sectionId}`);
  }

  async getTeamMarket() {
    return this.get<TeamMarket>(`/v2/teams/${this.sectionId}/market`);
  }

  async isLeaderboardEnabled() {
    try {
      await this.head(`/sections/${this.sectionId}/leaderboard`);
    } catch {
      return false;
    }
    return true;
  }

  async isSvsLeaderboardEnabled() {
    try {
      await this.head(`/accounts/${this.accountId}/leaderboard`);
    } catch {
      return false;
    }
    return true;
  }

  async makeContribution(itemId: string, amount: number) {
    return await this.post<ContributionEntry>(`/items/${itemId}/contributions/${this.userId}`, { amount });
  }

  async placeBid(itemId: string, bid: number): Promise<void> {
    await this.post(`/items/${itemId}/bids`, { user_id: this.userId, bid });
  }

  async purchaseItem(itemId: string, quantity: number) {
    return await this.purchaseItems([{ item_id: itemId, quantity }]);
  }

  async purchaseItems(items: { item_id: string; quantity?: number }[]) {
    return await this.post<Purchase[]>(`/users/${this.userId}/purchases`, items);
  }

  async purchaseRaffleTicket(itemId: string, quantity: number): Promise<void> {
    await this.post(`/v2/items/${itemId}/tickets`, { user_id: this.userId, quantity });
  }

  async setLastSeenPurchases(d: Date) {
    this.persistor.setItem('last_seen_purchases', d.getTime());
  }

  async submitMetaRedemption(redemptionIds: string[], data: any) {
    return await this.post<{ message: string } | undefined>(`/v2/users/${this.userId}/redemptions/submit`, {
      redemption_ids: redemptionIds,
      data,
    });
  }

  async submitRedemption(redemptionId: string, data: any) {
    return await this.post<{ message: string } | undefined>(`/v2/redemptions/${redemptionId}/submit`, data);
  }

  protected async get<T>(uri: string, params?: {}): Promise<T> {
    const qs = params ? queryString.stringify(params) : null;
    const search = qs ? `?${qs}` : '';
    return this.request(uri + search, null, 'GET');
  }

  protected async head<T>(uri: string, params?: {}): Promise<T> {
    const qs = params ? queryString.stringify(params) : null;
    const search = qs ? `?${qs}` : '';
    return this.request(uri + search, null, 'HEAD');
  }

  protected async post<T>(uri: string, data?: {}): Promise<T> {
    const body = data ? JSON.stringify(data) : null;
    const headers = { 'Content-Type': 'application/json; charset=utf-8' };
    return this.request(uri, body, 'POST', headers);
  }

  protected async request<T>(uri: string, body?: string | null, method: string = 'GET', headers?: {}): Promise<T> {
    method = method.toUpperCase();

    // Support relative and aboslute URL, also automatically uses v1 version
    //of the API unless otherwise specified.
    let url = uri;
    if (!uri.match(/^\/v[0-9]\//)) {
      url = `/v1${url}`;
    }
    if (!url.startsWith('http')) {
      url = `${this.apiUrl}${url}`;
    }

    const bearerToken = await this.getBearerToken();
    const finalHeaders = { ...headers, Authorization: `Bearer ${bearerToken}` };
    return fetch(url, {
      method,
      headers: finalHeaders,
      body,
    })
      .then(async (res) => {
        if (res.ok) {
          if (res.status !== 204) {
            const data = await res.json();

            // Follow pagination, but not more than a determined number of times.
            const links = parseLinkHeader(res.headers.get('Link') || '');
            if (method === 'GET' && Array.isArray(data) && links && links.next) {
              const nextPageData = await this.get<any[]>(links.next.url);
              if (!Array.isArray(nextPageData)) {
                return Promise.reject('Unexpected response.');
              }
              return data.concat(nextPageData);
            }

            return data;
          }
          return null;
        }

        let data = { code: 'UNCLASSIFIED_ERROR', message: 'Unclassified error' };
        try {
          data = await res.json();
        } catch {}

        let error = new RequestError();
        if (res.status === 404) {
          error = new NotFoundError('The resource could not be found');
        } else if (res.status === 400) {
          error = new BadRequestError(data.message, data.code);
        } else if (res.status === 401) {
          error = new AuthenticationError();
        } else if (res.status === 403) {
          error = new PermissionError();
        } else if (res.status === 424) {
          error = new FailedDependencyError(data.message, data.code);
        } else if (res.status >= 500) {
          error = new ServerError();
        }
        return Promise.reject(error);
      })
      .catch((err) => {
        switch (err.name) {
          case 'TypeError':
            err = new NetworkError(err.message);
            break;

          default:
            if (!err.name) {
              err = new UnknownError(err.message || 'Unknown error');
            }
            break;
        }
        return Promise.reject(err);
      })
      .catch((err) => {
        console.log('Request did not succeed', err?.name, err);
        this.notifyListeners(err.name, err);
        return Promise.reject(err);
      });
  }
}

/**
 * Repository using JWT authentication.
 */
export class JwtRepository extends UserRepository {
  protected accessToken: AccessToken;
  protected authManager: AuthenticationManager;
  protected refreshPromise: Promise<void> | undefined;

  constructor(apiUrl: string, accessToken: AccessToken, authManager: AuthenticationManager) {
    const payload = jwt.decode(
      accessToken.access_token.startsWith('jwt_') ? accessToken.access_token.substring(4) : accessToken.access_token
    ) as { id: string; school_id: string; account_id: string };

    if (!payload.id || !payload.school_id || !payload.account_id) {
      throw new Error('Invalid JWT');
    }

    super(apiUrl, payload.id, payload.school_id, payload.account_id);
    this.accessToken = accessToken;
    this.authManager = authManager;
  }

  getAccessToken() {
    return this.accessToken;
  }

  async getBearerToken(): Promise<string> {
    if (this.authManager.shouldRefreshToken(this.accessToken)) {
      if (!this.refreshPromise) {
        this.refreshPromise = this.refreshAndSaveToken();
      }
      await this.refreshPromise;
      this.refreshPromise = undefined;
    }
    return this.accessToken.access_token;
  }

  protected async refreshAndSaveToken(): Promise<void> {
    const accessToken = await this.authManager.refreshToken(this.accessToken);
    if (!accessToken) return;
    this.accessToken = accessToken;
    this.authManager.saveToken(accessToken);
  }
}

/**
 * Repository to test things statically.
 */
export class TestRepository extends DummyRepository {
  protected makeTestProduct(
    data: { [index: string]: any },
    variants?: { id?: string; name: string; quantity: number; remaining_stock: number }[]
  ): Product {
    let p: Product = {
      id: Date.now().toString(),
      type: ItemType.Auction,
      available_from: now() - 86400 * 10,
      available_until: now() + 86400 * 10,
      name: 'Yoga Session',
      description: 'The description for the item Yoga Session',
      quantity: 10,
      remaining_stock: 10,
      cost: 10,
      thumbnail_url: 'https://via.placeholder.com/200x200',
      image_url: 'https://via.placeholder.com/800x600',
      variants: null,
      item: null,
      ...data,
    } as any;
    p.item = variants
      ? null
      : {
          ...p,
          auction: data.auction || null,
          raffle: data.raffle || null,
          sweepstakes: data.sweepstakes || null,
          contribution: data.contribution || null,
          product: data.product || null,
          purchased: p.quantity ? p.quantity - p.remaining_stock : 0,
        };
    p.variants = !variants
      ? null
      : variants.map((v, i) => {
          const id = v.id || Date.now().toString() + `-${i}`;
          return {
            id: id,
            name: v.name,
            remaining_stock: v.remaining_stock,
            item: {
              ...p,
              id: id,
              name: p.name + ' - ' + v.name,
              auction: null,
              raffle: null,
              contribution: null,
              sweepstakes: null,
              product: {
                id: p.id,
                name: p.name,
                order: i,
                variant: v.name,
              },
              purchased: v.quantity ? v.quantity - v.remaining_stock : 0,
            },
          };
        });
    return p;
  }
  getBalance() {
    return Promise.resolve({ coins: 1234, tickets: 1234 });
  }
  getProducts() {
    return Promise.resolve([
      this.makeTestProduct({
        id: '1',
        type: ItemType.Auction,
        available_from: now() - 86400 * 10,
        available_until: now() + 86400 * 10,
        name: 'Yoga Session',
        description: 'The description for the item Yoga Session',
        quantity: 10,
        remaining_stock: 10,
        cost: 10,
        thumbnail_url: 'https://via.placeholder.com/200x200',
        image_url: 'https://via.placeholder.com/800x600',
        auction: {
          bid_increment: 3,
          handling_fee: 2,
          bid: null,
          bidder: null,
          count: 0,
        },
        raffle: null,
      }),
      this.makeTestProduct({
        id: '2',
        type: ItemType.Purchase,
        available_from: now() - 86400 * 10,
        available_until: now() + 86400 * 5,
        name: 'Chocolate Coin',
        description: 'The description for the item Chocolate Coin',
        quantity: 10,
        remaining_stock: 10,
        cost: 10,
        thumbnail_url: 'https://via.placeholder.com/200x200',
        image_url: 'https://via.placeholder.com/800x600',
        auction: null,
        raffle: null,
      }),
      this.makeTestProduct({
        id: '3',
        type: ItemType.Purchase,
        available_from: now() - 86400 * 10,
        available_until: now() + 86400,
        name: 'Waterproof Jacket',
        description: 'The description for the item Waterproof Jacket',
        quantity: 10,
        remaining_stock: 10,
        cost: 125,
        thumbnail_url: 'https://via.placeholder.com/200x200',
        image_url: 'https://via.placeholder.com/800x600',
        auction: null,
        raffle: null,
      }),
      this.makeTestProduct({
        id: '4',
        type: ItemType.Purchase,
        available_from: now() - 86400 * 10,
        available_until: now() + 86400 * 20,
        name: 'Waterproof Jacket',
        description: 'The description for the item Waterproof Jacket',
        quantity: 10,
        remaining_stock: 2,
        cost: 125,
        thumbnail_url: 'https://via.placeholder.com/200x200',
        image_url: 'https://via.placeholder.com/800x600',
        auction: null,
        raffle: null,
      }),
      this.makeTestProduct({
        id: '5',
        type: ItemType.Raffle,
        available_from: now() - 86400 * 10,
        available_until: now() + 86400 * 20,
        name: 'Burger and a beer',
        description: 'Burger and The description for the item a beer',
        quantity: 5,
        remaining_stock: 5,
        cost: 15,
        thumbnail_url: 'https://via.placeholder.com/200x200',
        image_url: 'https://via.placeholder.com/800x600',
        auction: null,
        raffle: {
          tickets: [
            { user_id: '_', quantity: 12 },
            { user_id: '_2', quantity: 4 },
            { user_id: '_3', quantity: 15 },
          ],
        },
      }),
      this.makeTestProduct({
        id: '6',
        type: ItemType.Raffle,
        available_from: now() - 86400 * 10,
        available_until: now() + 86400 * 20,
        name: 'Weekend Concert',
        description: 'The description for the item Weekend Concert',
        quantity: 5,
        remaining_stock: 0,
        cost: 299,
        thumbnail_url: 'https://via.placeholder.com/200x200',
        image_url: 'https://via.placeholder.com/800x600',
        auction: null,
        raffle: {
          tickets: [
            { user_id: 'abc123', quantity: 12 },
            { user_id: '_', quantity: 18 },
          ],
        },
      }),
      this.makeTestProduct({
        id: '7',
        type: ItemType.Auction,
        available_from: now() - 86400 * 10,
        available_until: now() + 86400 * 10,
        name: 'iPhone 11',
        description:
          'Ever dreamt of owning a magnificient piece of technology? Here is your chance, this phone can be auctioned with your favourite coins!',
        quantity: 1,
        remaining_stock: 1,
        cost: 100,
        thumbnail_url: 'https://via.placeholder.com/200x200',
        image_url: 'https://via.placeholder.com/800x600',
        auction: {
          bid_increment: 10,
          handling_fee: 5,
          bid: 120,
          bidder: 'abc123',
          count: 2,
        },
        raffle: null,
      }),
      this.makeTestProduct({
        id: '8',
        type: ItemType.Raffle,
        available_from: now() - 86400 * 10,
        available_until: now() + 86400 * 20,
        name: 'Pencil case',
        description: "The Tim Burton's pencil case. Keeps your pencil protected at all times.",
        quantity: 3,
        remaining_stock: 3,
        cost: 2,
        thumbnail_url: 'https://via.placeholder.com/200x200',
        image_url: 'https://via.placeholder.com/800x600',
        auction: null,
        raffle: {
          tickets: [],
        },
      }),
      this.makeTestProduct({
        id: '9',
        type: ItemType.Auction,
        available_from: now() - 86400 * 10,
        available_until: now() + 86400 * 10,
        name: 'Google Pixel 4',
        description:
          'Ever dreamt of owning a magnificient piece of technology? Here is your chance, this phone can be auctioned with your favourite coins!',
        quantity: 1,
        remaining_stock: 1,
        cost: 800,
        thumbnail_url: 'https://via.placeholder.com/200x200',
        image_url: 'https://via.placeholder.com/800x600',
        auction: {
          bid_increment: 50,
          handling_fee: 10,
          bid: 1850,
          bidder: 'someone-else',
          count: 18,
        },
        raffle: null,
      }),
      this.makeTestProduct(
        {
          id: '10',
          type: ItemType.Purchase,
          name: 'Cool T-shirt',
          description: "The best t-shirt you've ever seen!",
          quantity: 10,
          remaining_stock: 8,
          cost: 25,
          auction: null,
          raffle: null,
        },
        [
          {
            id: '10S',
            name: "Small but this is a long text and I don't know what's going to happen.",
            quantity: 2,
            remaining_stock: 2,
          },
          { id: '10M', name: 'M', quantity: 3, remaining_stock: 0 },
          { id: '10L', name: 'L', quantity: 2, remaining_stock: 1 },
          { id: '10XL', name: 'XL', quantity: 3, remaining_stock: 0 },
        ]
      ),
      this.makeTestProduct(
        {
          id: '11',
          type: ItemType.Purchase,
          name: 'Popular T-shirt',
          description: 'This will be sold out in no time!',
          quantity: 10,
          remaining_stock: 0,
          cost: 25,
          auction: null,
          raffle: null,
        },
        [
          {
            id: '10S',
            name: "Small but this is a long text and I don't know what's going to happen.",
            quantity: 2,
            remaining_stock: 0,
          },
          { id: '10M', name: 'M', quantity: 3, remaining_stock: 0 },
          { id: '10L', name: 'L', quantity: 2, remaining_stock: 0 },
          { id: '10XL', name: 'XL', quantity: 3, remaining_stock: 0 },
        ]
      ),
      this.makeTestProduct({
        id: '12',
        type: ItemType.Purchase,
        name: 'Popular candy',
        description: 'This will be sold out in no time!',
        quantity: 10,
        remaining_stock: 0,
        cost: 1,
        auction: null,
        raffle: null,
      }),
    ]);
  }

  getPurchases() {
    return Promise.resolve([
      {
        id: 'pur1',
        item_id: 'item1',
        item: {
          id: 'item1',
          name: 'Half a day off',
          image_url: 'https://via.placeholder.com/500x500',
        },
        made_on: now() - 86400 * 3,
        redeemed_on: null,
        state: PurchaseState.Made,
      },
      {
        id: 'pur2',
        item_id: 'item2',
        item: {
          id: 'item2',
          name: 'The Best of Michael Jackson',
          image_url: 'https://via.placeholder.com/500x500',
        },
        made_on: now() - 86400 * 10,
        redeemed_on: null,
        state: PurchaseState.Redeeming,
      },
      {
        id: 'pur3',
        item_id: 'item3',
        item: {
          id: 'item3',
          name: 'Wood Fire Pizza',
          image_url: 'https://via.placeholder.com/500x500',
        },
        made_on: now() - 86400 * 10,
        redeemed_on: now() - 86400 * 3,
        state: PurchaseState.Redeemed,
      },
    ]);
  }

  getRecentActivity() {
    return Promise.resolve([
      {
        amount: 10,
        summary: 'Activity completed',
        lang: {
          string: 'abc',
          args: {},
        },
        recorded_on: now() - 86400 * 3,
      },
      {
        amount: 24,
        summary: 'Course completed',
        lang: {
          string: 'abc',
          args: {},
        },
        recorded_on: now() - 86400 * 4,
      },
      {
        amount: 77,
        summary: 'Lesson completed',
        lang: {
          string: 'abc',
          args: {},
        },
        recorded_on: now() - 86400 * 4,
      },
    ]);
  }
}
