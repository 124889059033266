import React from 'react';
import Coins from './Coins';
import { _ } from '../lib/l18n';
import Button from './Button';
import { NotificationError } from './Notification';
import ContentContainer from './layouts/ContentContainer';

/**
 * Cart component.
 *
 * Usage:
 *  <Cart total={50}>
 *    <CartItemList>
 *      <CartItem
 *          name="Kayaking Trip"
 *          description="One full day outside with a guide"
 *          total={15}
 *          quantity={1}
 *          image="https://via.placeholder.com/200x200"
 *      />
 *      <CartItem
 *          name="Kayaking Trip"
 *          description="One full day outside with a guide, One full day outside with a guide, One full day outside with a guide, One full day outside with a guide, One full day outside with a guide, One full day outside with a guide,One full day outside with a guide"
 *          total={15}
 *          quantity={1}
 *          image="https://via.placeholder.com/200x200"
 *      />
 *    </CartItemList>
 *  </Cart>
 */
const Cart: React.FC<{
  total: number;
  onPlaceOrder: () => void;
  canPlaceOrder: boolean;
  maxQuantity?: number;
  maxQuantityExceeded?: boolean;
  error?: string;
  onErrorClose?: () => void;
}> = ({ children, total, onPlaceOrder, canPlaceOrder, maxQuantity, maxQuantityExceeded, error, onErrorClose }) => {
  return (
    <ContentContainer>
      <div className="flex flex-col lg:flex-row lg:gap-10">
        <div className="mb-4 flex-1">
          {error ? (
            <div className="mb-4">
              <NotificationError onClose={onErrorClose}>{error}</NotificationError>{' '}
            </div>
          ) : null}
          {children}
        </div>
        <div className="lg:w-80 lg:flex-width">
          <h1 className="text-3xl">{_('yourCart')}</h1>
          <div className="flex items-center mb-4 lg:mb-10">
            {_('total')}
            <Coins amount={total} className="text-xl font-medium ms-2" />
          </div>
          <div className="mb-4">
            <Button primary disabled={!canPlaceOrder} onClick={onPlaceOrder}>
              {_('placeorder')}
            </Button>
          </div>
          {maxQuantity && maxQuantityExceeded ? (
            <div className="mt-4 italic text-grey-slate text-sm">
              {_('orderUpToXItemsAtOnce', {
                number: maxQuantity,
              })}
            </div>
          ) : null}
          <div className="mt-4 italic text-grey-slate">{_('disclaimer.placeorder')}</div>
        </div>
      </div>
    </ContentContainer>
  );
};

export default Cart;
