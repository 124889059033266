import React, { useEffect, useState } from 'react';
import BlankPage from './layouts/BlankPage';

const LoadingScreen: React.FC<{ title: string; message: string }> = ({ title, message }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => setShow(true), 300);
    return () => clearTimeout(t);
  }, []);

  return (
    <BlankPage>
      <div className={`${!show ? 'opacity-0' : ''} flex flex-1 justify-center items-center flex-col text-center`}>
        <div className="max-w-sm">
          <h3 className="text-lg font-medium">{title}</h3>
          <p className="text-grey-slate">{message}</p>
        </div>
      </div>
    </BlankPage>
  );
};

export default LoadingScreen;
