import React from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { _ } from '../lib/l18n';
import { LeaderboardEntry, LeaderboardSectionEntry, LeaderboardUserEntry } from '../lib/types';
import { LeaderboardFilter } from '../lib/types.local';
import { getLeaderboardFilterUrl } from '../lib/urls';
import { useLeaderboardStore, useUserStore } from '../state/store';
import Coins from './Coins';
import ContentContainer from './layouts/ContentContainer';
import PillMenu, { PillMenuItem } from './PillMenu';
import { useRepository } from '../lib/hooks';

const Leaderboard: React.FC = (props) => {
  const { push } = useHistory();
  const leaderboardStore = useLeaderboardStore();
  const params = useParams<{ filter?: LeaderboardFilter }>();

  if (!leaderboardStore.anyEnabled) {
    return null;
  }

  const availableFilters = [
    leaderboardStore.leaderboardEnabled ? LeaderboardFilter.Solo : null,
    leaderboardStore.svsLeaderboardEnabled ? LeaderboardFilter.Team : null,
  ].filter(Boolean) as LeaderboardFilter[];

  const isValidFilter = (filter?: LeaderboardFilter) => {
    return filter && availableFilters.includes(filter);
  };

  const filter = params.filter && isValidFilter(params.filter) ? params.filter : availableFilters[0];
  const onFilterChange = (filter: LeaderboardFilter) => {
    push(getLeaderboardFilterUrl(filter));
  };

  return (
    <ContentContainer className="flex flex-col flex-grow">
      <div className="mb-4">
        <PillMenu>
          {availableFilters.map((pillFilter) => {
            let name = _('individualRankings');
            if (pillFilter === LeaderboardFilter.Team) {
              name = _('teamRankings');
            }
            return (
              <PillMenuItem onClick={() => onFilterChange(pillFilter)} active={filter === pillFilter} key={pillFilter}>
                {name}
              </PillMenuItem>
            );
          })}
        </PillMenu>
      </div>
      <div className="max-w-2xl w-full">
        <RankingLoader filter={filter} />
      </div>
    </ContentContainer>
  );
};

const RankingLoader: React.FC<{ filter: LeaderboardFilter }> = ({ filter }) => {
  const repository = useRepository();
  const user = useUserStore();
  const { isLoading, data } = useQuery(['leaderboards', filter], (): Promise<LeaderboardEntry[]> => {
    if (filter === LeaderboardFilter.Team) {
      return repository.getSvsLeaderboard();
    }
    return repository.getLeaderboard();
  });
  return (
    <Ranking
      isLoading={isLoading}
      data={data}
      filter={filter}
      myId={filter === LeaderboardFilter.Team ? user.sectionId : user.id}
    />
  );
};

const Ranking: React.FC<{
  isLoading: boolean;
  filter: LeaderboardFilter;
  data?: LeaderboardEntry[];
  myId?: string;
}> = ({ filter, isLoading, data, myId }) => {
  if (isLoading || !data) {
    return null;
  }

  return (
    <div className="w-full">
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-start border-blue-cloudy border-b py-3 px-4 w-16">{_('rank')}</th>
            <th className="text-start border-blue-cloudy border-b py-3 px-4">{_('name')}</th>
            <th className="text-start border-blue-cloudy border-b py-3">{_('score')}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, idx) => {
            let name;
            let rowId: string | number | null = null;

            if (filter === LeaderboardFilter.Solo) {
              const entry = row as LeaderboardUserEntry;
              name = `${entry.user.firstname || ''} ${entry.user.lastname || ''}`;
              rowId = entry.user.id;
              if (entry.user.anonymous || !name.trim()) {
                name = _('anonymousPerson');
              }
            } else if (filter === LeaderboardFilter.Team) {
              const entry = row as LeaderboardSectionEntry;
              name = entry.section.name || '';
              rowId = entry.section.id;
              if (entry.section.anonymous || !name.trim()) {
                name = _('anonymousTeam');
              }
            }

            const id = rowId || idx;
            let nameSuffix = '';
            let myRow = id === myId;
            if (myRow) {
              nameSuffix = filter === LeaderboardFilter.Team ? _('yoursParens') : _('youParens');
            }

            const additionalClasses = [myRow ? 'bg-grey-light' : null].filter(Boolean).join(' ');

            return (
              <tr key={id}>
                <td className={`${additionalClasses} border-blue-cloudy border-b py-8 px-4 font-medium`}>{row.rank}</td>
                <td className={`${additionalClasses} border-blue-cloudy border-b py-8 px-4`}>
                  {name || ''} {nameSuffix}
                </td>
                <td className={`${additionalClasses} border-blue-cloudy border-b py-8`}>{row.value.toLocaleString()}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;
